import React from 'react'
import { useLocation, Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { toggleDeleteCourseModal, toggleCoursePopOver, closeCoursePopOver, toggleEditCourseModal, fillEditInputs} from '../../slices/courseSlice'
import {addCartItem } from '../../slices/cartSlice'
import {standardNumber, standardRate} from '../../utils/utilsFunctions'
import { RxCross2 } from "react-icons/rx";
import styled from 'styled-components'


const SingleCourseSection = ({_id, course_imageUrl, course_title, course_price, course_description, course_videoUrl}) => {
    const { coursePopOverOpen, selectedCourseElem} = useSelector((store) => store.course);
    const { isAdmin, myCourses} = useSelector((store) => store.user);
    const { addCartLoad, selectedElemInd} = useSelector((store) => store.cart);
    const { selectedRate, rates, conversionRate} = useSelector((store) => store.event);
    const getRateSchema = rates.find(val => val.code === selectedRate)
    const {icon} = getRateSchema
    const dispatch = useDispatch()
    
    

  return (
    <Wrapper>

        <div className="image-container">
            <img src={course_imageUrl} alt={course_imageUrl} />

            {isAdmin && <div className="menu-container" onClick={() => {
                dispatch(toggleCoursePopOver(_id))
            }}>
                <HiOutlineDotsVertical />
            </div>}
            
            {isAdmin && <div className="controls-container" style={{visibility: `${(selectedCourseElem === _id && coursePopOverOpen) ? 'visible' : 'hidden'}`}}>
                <div className="controls-cls-container" onClick={() => dispatch(closeCoursePopOver(_id))}>
                    <RxCross2/>
                </div>
                <div className="btn-container">
                    <div className="btn edit" onClick={() => {
                    dispatch(toggleEditCourseModal())
                    dispatch(fillEditInputs({_id, course_price, course_imageUrl, course_description, course_title, course_videoUrl}))
                }}>
                    <FaEdit className='popover-icon edit'/>
                    Edit
                    </div>
                    <div className="btn del" onClick={() => dispatch(toggleDeleteCourseModal({course_title, _id}))}>
                    <MdDelete className='popover-icon'/>
                    Delete
                    </div>
                </div>
            </div>} 

        </div>

        <div className="course-details-container">
            <div className="name-price-container">
                <h3>{course_title}</h3>
                <h3>{icon}{standardNumber(standardRate(course_price, conversionRate))}</h3>
            </div>
            <p>{course_description}</p>

        {Array.isArray(myCourses) && myCourses.length > 0 ? (
            myCourses.some(course => course._id === _id) ? (
            
            <a href={myCourses.find(course => course._id === _id)?.course_videoUrl} key={_id}>
                <button type="button" className="enroll-btn">View Course</button>
            </a>
            ) : (
            <button
                type="button"
                className={`enroll-btn ${addCartLoad && selectedElemInd === _id ? 'btn-loading' : ''}`}
                onClick={() => dispatch(addCartItem({ type: 'course', courseId: _id, elemInd: _id }))}
            >
                Enroll Now
            </button>
            )
        ) : (
            <button
            type="button"
            className={`enroll-btn ${addCartLoad && selectedElemInd === _id ? 'btn-loading' : ''}`}
            onClick={() => dispatch(addCartItem({ type: 'course', courseId: _id, elemInd: _id }))}
            >
            Enroll Now
            </button>
        )
        }

            
         </div>

        </Wrapper>
  )
}


const Wrapper = styled.div`

        margin-top: 2rem;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: solid 1px var(--stroke-color);

    .image-container {
        height: auto;
        width: 100%;
        position: relative;

        img {
            background: #f5f5f5;
            height: 100%;
            min-height: 100px;
            max-height: 200px;
            width: 100%;
            object-fit: cover;
        }

        .controls-container {
            position: absolute;
            height: 70%;
            width: 100%;
            bottom: 0;
            background: var(--highlight-color);
            color: var(--white-color);
            border-top: solid 1px black;
            border-bottom: solid 1px black;
            display: flex;
            flex-direction: column;
        }

        .controls-cls-container {
            width: 100%;
            height: 30%;
            padding: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            font-size: 1.5em;
            border-bottom: solid 0.1px var(--stroke-color);
            cursor: pointer;
        }

        .btn-container {
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            background: #f5f5f5;
        }

        .btn {
            width: 100%;
            height: 100%;
            background: none;
            color: var(--white-color);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            user-select: none;
            border-top: solid 0.1px var(--stroke-color);
            padding: 0.5rem;
        }

        .edit {
            color: #2d3748;
            background: var(--secondary-color);
        }

        .del {
            background: #f2dede;
            color: var(--error-color);
            border-top: solid 0.1px var(--stroke-color);
        }


        .popover-icon {
            margin-right: 1rem;
            font-size: 0.95em;
            opacity: 0.8;
        }


        .menu-container {
            position: absolute;
            top: 5%;
            right: 5%;
            width: 30px;
            height: 30px;
            background: var(--white-color);
            color: var(--stroke-color);
            display: grid;
            place-content: center;
            cursor: pointer;
        }

    }

    .course-details-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 0.9em;
        padding: 1rem;


        .name-price-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            :last-child {
                margin-left: 1rem;
            }
        }

        p {
            margin-top: 1.5rem;
            width: 100%;
            text-align: start;
            font-size: 0.95em;

            .read-more-btn {
                font-size: 1.1em;
                cursor: pointer;
                user-select: none;
            }
        }


        a {
            width: 100%;
        }

        button {
            margin-top: 1.5rem;
            width: 100%;
            height: 50px;
            cursor: pointer;
            background: var(--highlight-color);
            color: var(--white-color);
            border: none;
            cursor: pointer;
            font-size: 1.1em;
        }

        .btn-loading {
            opacity: 0.8;
        }
    }

    @media only screen and (min-width: 600px) {
        
    }

    @media only screen and (min-width: 768px) {

        width: 100%;
        height: auto;

        .course-menu-popover {

        }
        
        button {
            margin-top: 3rem;
        }
    }

`


export default SingleCourseSection