import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import './styles/main.css'
import {LoadingPage} from './pages'
const App = lazy(() => import('./App'))
const LoadingFallback = () => <div>Loading...</div>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <React.StrictMode>
    <BrowserRouter >
      <Provider store = { store }>
        <Suspense fallback={<LoadingPage/>}>
            <App/>
        </Suspense>
      </Provider> 
    </BrowserRouter> 
    </React.StrictMode>
);