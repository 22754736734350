import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import ProfileCourses from './ProfileCourses'
import ProfileOrders from './ProfileOrders'
import ProfileLogout from './ProfileLogout'

const data = [{
  id: 0,
  component: <ProfileCourses/>
}, 
{
  id: 1,
  component: <ProfileLogout/>
}
]

const ProfileSection = () => {
  const {
    activeComponent
  } = useSelector((store) => store.profile)
  return (
    <Wrapper>
      {data.filter(val => val.id === activeComponent).map((item) => {
        return <div key={item.id}>
          {item.component}
        </div>
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
export default ProfileSection