
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../paths/url';

const storedCheckoutInputs = localStorage.getItem('courseCheckoutInputs')
  ? JSON.parse(localStorage.getItem('courseCheckoutInputs'))
  : {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    };

const storedPaymentInitialization = localStorage.getItem('paymentInitialized')
    ? JSON.parse(localStorage.getItem('paymentInitialized'))
    : false

const storeOrderId = localStorage.getItem('orderId')
? JSON.parse(localStorage.getItem('orderId'))
: ''

const initialState = {
  courseCheckoutInputs: storedCheckoutInputs,
  orderLoad: false,
  orderError: false,
  orderErrorMessage: '',
  orderId: storeOrderId,

  // payment initiated
  paymentInitiated: storedPaymentInitialization,
  paymentConfirmationLoad: false,
  paymentCompleted: false,
  paymentRejected: false,
  paymentError: false,
  paymentErrorMsg: '',
  paymentTrue: false,
  orderDetails: [],
  coursePurchaseVideoDetails: []
  // paymentCompleted: false,
  // paymentError: false,
  // paymentErrorMsg: '',
  // paymentTrue: false,
  // orderDetails: [],
  // coursePurchaseVideoDetails: []

};

export const initializePayment = createAsyncThunk(
  'actions/initializePayment',
  async (payload, thunkAPI) => {
    const { email, firstName, lastName, phone } = payload;
    const currency = thunkAPI.getState().event.selectedRate;

    try {
      const resp = await axios.post(
        `${URL}/api/v1/order/payment?currency=${currency}`,
        { email, firstName, lastName, phone },
        { withCredentials: true }
      );
      window.location.href = `${resp.data.authorization_url}`;
      
      return { response: resp.data, status: 'success' };
    } catch (error) {
      return {
        response: error.response.data,
        status: 'error',
        code: error.response.status,
      };
    }
  }
);

export const verifyPayment = createAsyncThunk(
  'actions/verifyPayment',
  async (payload, thunkAPI) => {
    const { id, reference } = payload;
    try {
      const resp = await axios.get(
        `${URL}/api/v1/order/verifyPayment/${id}?reference=${reference}`,
        { withCredentials: true }
      );
      return { response: resp.data, status: 'success' };
    } catch (error) {
      return {
        response: error.response.data,
        status: 'error',
        code: error.response.status,
      };
    }
  }
); 

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    fillCourseCheckout: (state, action) => {
      const { email, firstName, lastName, phone } = action.payload;
      state.courseCheckoutInputs.email = email;
      state.courseCheckoutInputs.firstName = firstName;
      state.courseCheckoutInputs.lastName = lastName;
      state.courseCheckoutInputs.phone = phone;

      localStorage.setItem(
        'courseCheckoutInputs',
        JSON.stringify(state.courseCheckoutInputs)
      );
    },
    cancelInitiatedOrder: (state, action) => {
      state.paymentInitiated = false;
      localStorage.removeItem('courseCheckoutInputs');
      localStorage.removeItem('paymentInitialized');
      localStorage.removeItem('orderId');
    },
    updatePaymentStatus: (state, action) => {
      state.status = action.payload.msg;  
      state.message = action.payload.msg;  
      state.event = action.payload.event;  
    },
    handleCloseOnFinishedOrder: (state, action) => {
      window.location.href = '/';
      state.paymentInitiated = false
      state.paymentConfirmationLoad = false
      state.paymentCompleted = false
      state.paymentError = false
      state.paymentTrue = false 
      state.orderDetails = []
      state.coursePurchaseVideoDetails = []
      localStorage.removeItem('courseCheckoutInputs');
      localStorage.removeItem('paymentInitialized');
      localStorage.removeItem('orderId');
      localStorage.removeItem('storedCart');
      
    },

  },

  extraReducers: (builder) => {
    // Payment initialization
    builder
      .addCase(initializePayment.pending, (state) => {
        state.orderLoad = true;
        state.orderError = false;
      })
      .addCase(initializePayment.fulfilled, (state, action) => {
        const { response, status } = action.payload;
        if (status === 'success') {
          state.orderLoad = false;
          state.paymentInitiated = true;
          state.orderId = response.orderId;
          localStorage.setItem(
            'paymentInitialized',
            JSON.stringify(state.paymentInitiated)
          );
          localStorage.setItem(
            'orderId',
            JSON.stringify(state.orderId)
          );
        } else {
          state.orderLoad = false;
          state.orderError = true;
          state.orderErrorMessage = response.msg;
        }
      })
      .addCase(initializePayment.rejected, (state) => {
        state.orderLoad = false;
        state.orderError = true;
      })

    // Payment verification
      .addCase(verifyPayment.pending, (state) => {
        state.paymentConfirmationLoad = true
        state.paymentErrorMsg = ''

      })
      .addCase(verifyPayment.fulfilled, (state, action) => {
        const { response, status } = action.payload;
        state.paymentConfirmationLoad = false
        state.paymentRejected = false
        if (status === 'success') {
          
            if (response.status === 'Approved' || response.status === 'Successful' || response.paid_at || response.paymentStatus) {
              state.paymentError = false
              state.paymentCompleted = true
              state.paymentTrue = true
              state.orderDetails = response.order || {};
              state.coursePurchaseVideoDetails = response.order.coursePurchaseDetails?.videoDetails || []
              localStorage.removeItem('courseCheckoutInputs');
              localStorage.removeItem('paymentInitialized');
              localStorage.removeItem('orderId');
              localStorage.removeItem('storedCart');
            } else {
              state.paymentCompleted = true
              state.paymentTrue = false
              state.paymentError = true
              state.paymentErrorMsg = 'Payment failed'

            }
        } else {
          if (!state.paymentCompleted) {
            state.paymentError = true;
            state.paymentCompleted = false;
            state.paymentErrorMsg = response.msg;
          }
        }
      })
      .addCase(verifyPayment.rejected, (state) => {
        state.paymentConfirmationLoad = true
        state.paymentRejected = true
        state.paymentError = false
        // state.paymentErrorMsg = 'Payment failed'
      })

  }

})

export default checkoutSlice.reducer;
export const {
  fillCourseCheckout,
  cancelInitiatedOrder,
  updatePaymentStatus,
  handlePayment,
  handleCloseOnFinishedOrder
  // setPaymentEvent,
  // handleCloseOnFinishedOrder,
  // updatePaymentStatus,
} = checkoutSlice.actions;
