import React from 'react'
import {HeroSection, ServiceSection, ProductSection, Support} from '../components/home'
import styled from 'styled-components'

const HomePage = () => {
  return (
    <Wrapper>
        <HeroSection/>
        <Support/>
        <ServiceSection/>
        <ProductSection/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  padding-bottom: 40em;

  @media only screen and (min-width: 992px) {
    padding-bottom: 50em;
  }
`
export default HomePage