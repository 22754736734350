import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {AlertError} from '../helpers'
import styled from 'styled-components'
import { Link, useLocation} from 'react-router-dom';
import {fillAuthInputs, changePassword} from '../slices/authSlice'



const ChangePasswordPage = () => {
    const location = useLocation();
    const {
        changePasswordInputs: {
            newPassword,
            confirmPassword
        },
        changePasswordLoad,
        changePasswordError,
        errorMessage
      } = useSelector((store) => store.auth)
      const dispatch = useDispatch()
      const query = new URLSearchParams(location.search);
      const token = query.get('token')

  return (
    <Wrapper>
        <h1>Change your password</h1>
        <p>Choose a new password for your account</p>

        <form >
        {changePasswordError && <AlertError message={errorMessage}/>}
            <label htmlFor="password">
                <h3>New Password</h3>
                <input type="pasword" 
                required
                value={newPassword}
            onChange={(e) =>
              dispatch(
                fillAuthInputs({
                  newPassword: e.target.value,
                  confirmPassword,
                  type: 'change-password',
                })
              )
            }
                />
            </label>
            <label htmlFor="confirm password">
                <h3>Confirm Password</h3>
                <input type="password" 
                required
                value={confirmPassword}
            onChange={(e) =>
              dispatch(
                fillAuthInputs({
                  confirmPassword: e.target.value,
                  newPassword,
                  type: 'change-password',
                })
              )
            }
                />
            </label>
            <button type="submit" className={`${changePasswordLoad && 'btn-loading'}`} onClick={() => dispatch(changePassword({token, newPassword, confirmPassword}))}>
                Login
            </button>
        </form>
        <Link to={'/login'}>
        <p className='back-btn'>Go back</p>
        </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100vw;
    padding-bottom: 40em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        color: var(--header-text-color);
        margin-top: 10rem;
    }

    p {
        margin-top: 1rem;
        text-align: center;
        width: 80%;
    }

    form {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
    }

    h3 {
        margin-top: 1rem;
    }

    label {
        width: 100%;
    }

    input {
            width: 100%;
            height: 50px;
            padding: 0 2rem 0 1rem;
            margin-top: 0.5rem;
            border: solid 2px var(--stroke-color);
            font-size: 1em;
            outline: none;
    }

    button {
        width: 100%;
        height: 50px;
        display: grid;
        place-content: center;
        font-size: 1.2em;
        border: none;
        color: var(--white-color);
        margin-top: 1.5rem;
        background: var(--highlight-color);
    }

    .btn-loading {
        opacity: 0.8;
    }

    a {
        width: 90%;
    }

    .back-btn {
        width: 100%;
        text-align: end;
        text-decoration: underline;
    }

    @media only screen and (min-width: 768px) {
    form {
        width: 60%;
    }

    a {
        width: 60%;
    }
    }

    @media only screen and (min-width: 992px) {
        padding-bottom: 50em;
        
        form {
            width: 40%;
        }

        a {
        width: 40%;
    }
    }

`

export default ChangePasswordPage