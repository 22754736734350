import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {URL} from '../paths/url'

const initialState = {
    email: '',
    waitListJoinLoad: false,
    waitListJoinError: false,
    waitListErrorMsg: '',
    waitListSuccessMsg: '',
    joinSuccess: false
}

export const joinWaitList = createAsyncThunk(
    'actions/joinWaitList',
    async (payload, thunkAPI) => {
      const { email } = payload
      try {
        const resp = await axios.post(
          `${URL}/api/v1/waitlist`,
          {
            email,
          },
          {
            withCredentials: true,
          }
        )
        return { response: resp, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  

  

  

const authSlice = createSlice({
    name: 'waitlist',
    initialState,
    reducers: {
        fillWaitList: (state, action) => {
            state.email = action.payload
        }
    },

    extraReducers(builder) {
        builder
        .addCase(joinWaitList.pending, (state, action) => {
            state.waitListJoinLoad = true
            state.waitListJoinError = false
          })
          .addCase(joinWaitList.fulfilled, (state, action) => {
            state.waitListJoinLoad = true
            const { status, code, response } = action.payload
            if (code === 500) {
              state.waitListJoinLoad = false
              state.waitListJoinError = true
              state.waitListErrorMsg = `Can't join due to network`

            } else if (status === 'success') {
              state.email = ''
              state.waitListJoinLoad = false
              state.waitListJoinError = false
              state.joinSuccess = true
              state.waitListSuccessMsg = response.data.msg

            } else {
              state.waitListJoinLoad = false
              state.waitListJoinError = true
              state.waitListErrorMsg = response.msg              
            }
          })
          .addCase(joinWaitList.rejected, (state, action) => {
            state.waitListJoinLoad = false
            state.waitListJoinError = true
            state.errorMessage = 'Unable to join'
          })
    }
      
    })


export default authSlice.reducer
export const {
  fillWaitList
} = authSlice.actions