import React, {useEffect} from 'react'
import styled from 'styled-components'
import {logout} from '../../slices/authSlice'
import { useSelector, useDispatch} from 'react-redux'


const ProfileLogout = () => {
    const dispatch = useDispatch();
    
    const {
    logoutLoad,
    logoutError,
    logoutErrorMsg,
      } = useSelector((store) => store.auth)

    useEffect(() => {
        dispatch(logout())
    }, [dispatch]);

  return (
    <Wrapper>
        {logoutLoad && !logoutError ? <div>Logging out...</div> 
        : !logoutLoad && logoutError ? <div>{logoutErrorMsg}</div>
        : <div>User loggout!</div>
        }
        
    </Wrapper>
  )
}

const Wrapper = styled.div``


export default ProfileLogout