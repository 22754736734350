
import React, { useEffect, useState, startTransition } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import WillykangaSC from '../../assets/videos/willykanga_herovideo_sc.mp4';
import WillykangaLC from '../../assets/videos/willykanga_herovideo.mp4';
import { scrollToBottom } from '../../utils/pageScroller';

const HeroSection = () => {
  const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    const updateVideoSource = () => {
      const width = window.innerWidth;
      if (width >= 768) {
        setVideoSrc(WillykangaLC);
      } else {
        setVideoSrc(WillykangaSC);
      }
    };

    startTransition(() => {
      updateVideoSource();
    });

    window.addEventListener('resize', updateVideoSource);

    return () => {
      window.removeEventListener('resize', updateVideoSource);
    };
  }, []);

  return (
    <Wrapper>
      <div className="video-container">
        <video
          src={videoSrc}
          autoPlay
          muted
          loop
          playsInline
          className="blurred-video"
        ></video>

        <div className="text-overlay">
          <div className="btn-container">
            <Link to={'/course'}>
              <button type="button" className="learn">
                <span className="btn-text">Buy course</span>
              </button>
            </Link>

            <Link to={'/'}>
              <button type="button" className="shop" onClick={scrollToBottom}>
                Buy merch
              </button>
            </Link>
          </div>

          <h3>The Long Wait Is Finally Over...</h3>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 3rem;

  .video-container {
    position: relative;
    width: 100%;
    min-width: 100vw;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
    z-index: 0; 
  }

  .blurred-video {
    width: 100vw;
    height: 100%;
    object-fit: cover;
    object-position: 50% 30%;
    z-index: 1; 
  }

  .text-overlay {
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--secondary-color);
    text-align: center;
    font-size: 2rem;
    text-shadow: 2px 2px 4px #0000007f;
    z-index: 2; 
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 0.5em;
      width: 100%;
      font-family: 'Inter', sans-serif;
      margin-top: 1rem;
      opacity: 0.6;
    }
  }

  .btn-container {
    margin-top: 1rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  a {
    width: 100%;
  }

  button {
    display: grid;
    place-content: center;
    width: 100%;
    height: 55px;
    margin: 0.25rem;
    padding: 1rem;
    cursor: pointer;
    font-size: 0.6em;
    background: var(--primary-color);
    color: var(--secondary-color);
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border: none;
    box-sizing: border-box;
    font-family: 'DK Frozen Memory', sans-serif;

    .btn-text {
      opacity: 0.8;
      color: var(--text-color);
    }
  }

  button:after {
    background-color: #1c1c1c;
    opacity: 0.8;
    content: '';
    display: block;
    height: 48px;
    left: 0;
    width: 100%;
    position: absolute;
    top: 3px;
    transform: translate(8px, 8px);
    transition: transform 0.2s ease-out;
    z-index: -1;
  }

  .learn {
    background: var(--secondary-color);
    color: #000000;
  }

  @media only screen and (min-width: 600px) {
    .blurred-video {
      object-position: 50% 25%;
    }
  }

  @media only screen and (min-width: 768px) {
    .blurred-video {
      object-position: 50% 50%;
    }
  }

  @media only screen and (min-width: 992px) {
    .blurred-video {
      object-position: 50% 20%;
    }

    .btn-container {
      width: 60%;
    }
  }

  @media only screen and (min-width: 1200px) {
    .blurred-video {
      object-position: 50% 10%;
    }

    .text-overlay {
      left: 20%;
    }

    .btn-container {
      width: 30%;
    }
  }
`;

export default HeroSection;
