import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {URL} from '../paths/url'

const initialState = {
   activeComponent: 0,
}


const authSlice = createSlice({
    name: 'waitlist',
    initialState,
    reducers: {
        updateActiveComponent: (state, action) => {
            state.activeComponent = action.payload
        }
    },

    extraReducers(builder) {
        
    }
      
    })


export default authSlice.reducer
export const {
  updateActiveComponent
} = authSlice.actions