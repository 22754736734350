import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { RxCross2 } from "react-icons/rx";
import { GoPlus} from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux'
import {toggleCartPopup, getCart} from '../../slices/cartSlice'
import SingleCartItem from './SingleCartItem'
import {standardNumber, standardRate} from '../../utils/utilsFunctions'
import {Loader} from '../../helpers'


const CartPopupDisplay = () => {
  const { cartPopupOpen,
    getCartLoad,
    isCartError,
    errorMessage,
    successMessage,
    cart,
    cartItems,
  } = useSelector((store) => store.cart);
  const { selectedRate, rates, conversionRate} = useSelector((store) => store.event);
    const getRateSchema = rates.find(val => val.code === selectedRate)
    const {icon} = getRateSchema
    const dispatch = useDispatch()

    useEffect(() => {
      if (cartPopupOpen) {
      dispatch(getCart())
      }
    }, [cartPopupOpen, dispatch]);

    const {subtotal, shippingFee, total} = cart || {}

    

    return (
  <Wrapper cartPopupOpen={cartPopupOpen}>
      <div className="header-container">
            <RxCross2 className='cancel-icon' onClick={() => dispatch(toggleCartPopup())} />
            <h1>Cart</h1>
            <div className="items-container">
              {cart && `${cartItems?.length} item${`${cartItems?.length > 1 ? '(s)' : ''}`}`}
            </div>
      </div>

      {
    getCartLoad && !isCartError && cartItems.length === 0 ? (
      <div className="cart-load">
        <Loader />
      </div>
    ) : cartItems.length > 0 ? (
      <>
        <div className="cart-parent-container">
          {Array.isArray(cartItems) &&
            cartItems.map((values) => {
              return <SingleCartItem {...values} key={values._id} />;
            })}
        </div>
        <div className="price-summary">
          <div className="sub-total-container">
            <p>Subtotal</p>
            <p>
              {icon}
              {standardNumber(standardRate(subtotal, conversionRate)) || 'loading..'}
            </p>
          </div>
          <div className="shipping-container">
            <p>Charges</p>
            <p>
              {icon}
              {standardNumber(standardRate(shippingFee, conversionRate)) || 'loading..'}
            </p>
          </div>
          <div className="total-container">
            <h3>Total</h3>
            <h3>
              {icon}
              {standardNumber(standardRate(total, conversionRate)) || 'loading..'}
            </h3>
          </div>
        </div>
        <div className="btn-container">
          <Link to={"/cart"}>
            <button
              type="button"
              className="view-cart-btn"
              onClick={() => dispatch(toggleCartPopup())}
            >
              View cart
            </button>
          </Link>
          <Link to={"/course-checkout"}>
            <button
              type="button"
              className="checkout-btn"
              onClick={() => dispatch(toggleCartPopup())}
            >
              Checkout
            </button>
          </Link>
        </div>
      </>
    ) : (
      <div className="empty-cart-container">
        <h3>Ooppss...Your cart is currently empty</h3>
        <Link to={"/course"}>
          <button
            type="button"
            className="empty-btn"
            onClick={() => dispatch(toggleCartPopup())}
          >
            Continue Shopping
          </button>
        </Link>
      </div>
    )
}
    {
}
        
    </Wrapper>
  )
}


const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background-color);
  color: var(--color-text-primary);
  min-height: 100vh;
  width: 100vw;
  transform: ${({ cartPopupOpen }) => (cartPopupOpen ? 'translateX(0)' : 'translateX(100%)')};
  z-index: var(--z-index-fixed-header);
  transition: transform var(--animation-duration) ease-in-out;
  overflow: auto;


  .header-container {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--spacing-sm);
    padding: var(--padding-md);


    h1 {
        width: auto;
        position: relative;
        color: var(--header-text-color);
    }

  }

  .cancel-icon {
    font-size: var(--font-size-xxl);
    cursor: pointer;
  }

  .cart-load {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .empty-cart-container {
    margin-top: var(--spacing-xl);
    height: 50vh;
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      width: 80%;
    }
  }

  .empty-btn {
      margin-top: var(--spacing-md);
      height: var(--standard-btn-height);
       width: 100%;
        display: grid;
        place-content: center;
        font-size: var(--font-size-md);
        border: none;
        color: var(--white-color);
        background: var(--highlight-color);
    }

    h3 {
      font-size: var( --font-size-md);
    }

    p {
      font-size: var(--font-size-sm);
      color: var(--text-color);
    }

    .cart-parent-container {
      margin-top: 2rem;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }


  .price-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
  }

  .price-summary {
        width: 90%;
        display: grid;
        grid-template-columns: auto;
        gap: 1rem;
        margin-top: 3rem;

        >div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: -0.5rem;
        }
  }


.btn-container {
  
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;

  a {
    width: 100%;
  }

  button {
    margin-top: 0.5rem;
    width: 100%;
    height: var(--standard-btn-height);
    font-size: 1.1em;
    border: solid 1px var(--stroke-color);
  }
 
}

.view-cart-btn {
  background: none;
  color: var(--color-text-primary);
  border: solid 2px var(--secondary-color);
}

.checkout-btn {
  background: var(--highlight-color);
  color: var(--white-color);
  border: none;
}


@media only screen and (min-width: 768px) {
  .btn-container{
      width: 50%;
    }

    .empty-cart-container {
    a {
      width: 50%;
    }
  }

}

@media only screen and (min-width: 992px) {
  .btn-container{
      width: 30%;
    }

    .empty-cart-container {
    a {
      width: 30%;
    }
  }
}


`
export default CartPopupDisplay