import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {ratesData} from '../utils/currencyRates'


const currencyConverterUrl = `https://api.currencyapi.com/v3/latest?apikey=${process.env.REACT_APP_CURRENCY_CONVERTER_APIKEY}&currencies=USD%2CNGN&base_currency=NGN
`
const currentLocationUrl = `https://ipinfo.io/102.91.93.63?token=${process.env.REACT_APP_CURRENT_LOCATION_APIKEY}`

const storeSelectedRate = localStorage.getItem('selectedRate') ? JSON.parse(localStorage.getItem('selectedRate')) : 'NGN'

const storeCoversionRate = localStorage.getItem('conversionRate') ? JSON.parse(localStorage.getItem('conversionRate')) : 1


// CURRENCY_CONVERTER_APIKEY=cur_live_7NvCG8hRWRJnbeeogn49zNvk9lpAlU1Ol0Z1NLMM
// CURRENT_LOCATION_APIKEY=faedfddd9feb86






const initialState = {
    navMenuOpen: false,
    currencyPopOverOpen: false,
    conversionLoading: false,
    isConversionError: false,
    rates: ratesData,
    currentRates: [],
    selectedRate: storeSelectedRate,
    conversionRate: storeCoversionRate,
    currLocationLoad: false,
    currLocationError: false,
    userIsAbroad: false,
}

export const getCurrencyRates = createAsyncThunk('event/converter', async (payload, thunkAPI) => {
    try {
      const resp = await axios.get(currencyConverterUrl);
      const rates = resp.data.data
      thunkAPI.dispatch(changeCurrency(payload))
      return {
        response: rates,
        status: 'success'
      }
    } catch (error) {
      return {
        response: error.response.data,
        status: 'error',
        code: error.response.status,
      }
    }
})

export const getCurrentLocation = createAsyncThunk('event/getCurrentLocation', async (payload, thunkAPI) => {
  try {
    const resp = await axios.get(currentLocationUrl);
    const country = resp.data.country
    // thunkAPI.dispatch(changeCurrency(payload))
    return {
      response: country,
      status: 'success'
    }
  } catch (error) {
    return {
      response: error.response.data,
      status: 'error',
      code: error.response.status,
    }
  }
})


const authSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        toggleNavMenu: (state, action) => {
            state.navMenuOpen = !state.navMenuOpen
        },
        closeNavMenu: (state, action) => {
          state.navMenuOpen = false
      },
        toggleCurrencyPopover: (state, action) => {
            state.currencyPopOverOpen = !state.currencyPopOverOpen
        },
        closeCurrencyPopover: (state, action) => {
          state.currencyPopOverOpen = false
        },
        changeCurrency: (state, action) => {
          state.selectedRate = action.payload
          state.rates = state.rates.map((values) => {
            if (values.code === state.selectedRate) {
              return {
                ...values,
                selected: true
              }
            } else {
              return {
                ...values,
                selected: false,
              }
            }
          })  
          
          state.currencyPopOverOpen = false     
          localStorage.setItem(
            'selectedRate',
            JSON.stringify(state.selectedRate)
          );           
        },
        executeConversion: (state, action) => {
          for (const key in action.payload) {
              if (key === state.selectedRate) {
                state.conversionRate = action.payload[key].value  
                localStorage.setItem(
                  'conversionRate',
                  JSON.stringify(state.conversionRate)
                );                      
              }
          }
        }
    },
    extraReducers(builders) {
      builders.addCase(getCurrencyRates.pending, (state, action) => {
        state.conversionLoading = true
      })
      .addCase(getCurrencyRates.fulfilled, (state, action) => {
        const { response, status} = action.payload
        state.conversionLoading = true
        if (status === 'success') {
          state.conversionLoading = false
          state.isConversionError = false
          state.currentRates = response
        }
      })
      .addCase(getCurrencyRates.rejected, (state, action) => {
        state.conversionLoading = false
        state.isConversionError = true
      })
      .addCase(getCurrentLocation.pending, (state, action) => {
        state.currLocationLoad = true
      })
      .addCase(getCurrentLocation.fulfilled, (state, action) => {
        const { response, status} = action.payload
        state.currLocationLoad = false        

        if (status === 'success') {
          state.currLocationError = false

              if (response !== 'NG') {
                state.userIsAbroad = true
                state.selectedRate = 'USD'
              } else {
                state.userIsAbroad = false
              }
        } 
      })
      .addCase(getCurrentLocation.rejected, (state, action) => {
        state.currLocationLoad = false
        state.currLocationError = true
      })
    }
})

export default authSlice.reducer
export const {
  toggleNavMenu,
  toggleCurrencyPopover,
  closeCurrencyPopover,
  changeCurrency,
  executeConversion,
  closeNavMenu
} = authSlice.actions