import React from 'react'
import {
  Navigate,
  useLocation,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

const CheckoutRoute = ({ children }) => {
  const { cart } = useSelector(
    (store) => store.user
  )
  const location = useLocation()

  if (!cart) {
    return <Navigate to='/' state={{ from: location }} replace />
  }

  return children
}

export default CheckoutRoute