import React from 'react'
import {
  Navigate,
  useLocation,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

const AdminRoute = ({ children }) => {
  const { isAdmin} = useSelector(
    (store) => store.user
  )
  const location = useLocation()

  if (!isAdmin) {
    return <Navigate to='/' state={{ from: location }} replace />
  }

  return children
}

export default AdminRoute