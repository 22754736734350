import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import {standardNumber, standardRate} from '../../utils/utilsFunctions'
// moment("20111031", "YYYYMMDD").fromNow();

const LastProductSold = ({productImage, productPrice, orderDate, productName, customer}) => {
    const { selectedRate, rates, conversionRate} = useSelector((store) => store.event);
    const getRateSchema = rates.find(val => val.code === selectedRate)
    const {icon} = getRateSchema

  return (
    <Wrapper className='container'>
        <div className='image-and-desc-container'>
            <img src={productImage} alt="" />
            <div className="description-container">
                <h3>{productName}</h3>
                <p className='price'>{icon}{standardNumber(standardRate(productPrice, conversionRate))}</p>
            </div>
        </div>
        <div className="customer">
            <p>{customer}</p>
        </div>
        <div className="date">
            <p>{moment(orderDate).format("L")}</p>
        </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1rem;
    border-bottom: solid 0.5px var(--stroke-color);
    cursor: pointer;
    user-select: none;
    overflow-x: auto;
    padding-bottom: 2rem;

    ::-webkit-scrollbar {
        width: 6px; 
    }

    ::-webkit-scrollbar-track {
        background: #d3d3d3; 
    }

    ::-webkit-scrollbar-thumb {
        background: var(--stroke-color);
     }


    :hover {
      .description-container h3 {
        text-decoration: underline;
      }
    }

    .image-and-desc-container {
        height: 100%;
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        background: #f5f5f5;
    }

    .description-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 1rem;

        p {
            margin-top: 0.5rem;
            font-size: 0.9em;
            opacity: 0.8;
        }
    }

    .customer {
        margin-left: 1.5rem;
        text-align: center;
    }


    .date {
        margin-left: 1.5rem;
        text-align: center;
        font-size: 0.9em;
        opacity: 0.8;
    }

    @media only screen and (min-width: 600px) {
        min-height: 80px;
    }

    @media only screen and (min-width: 992px) {

        img {
            height: 50px;
            width: 100px;
        }
        .customer {
            margin-left: 0rem;
        }
    }


`
export default LastProductSold