import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link} from 'react-router-dom';
import {AlertError} from '../helpers'
import {fillAuthInputs, resetPassword} from '../slices/authSlice'



const ResetPasswordPage = () => {
    const {
    resetPasswordEmail,
    resetPasswordLoad,
    resetPasswordError,
    resetPasswordSent,
    errorMessage,
    passwordResetMessage
      } = useSelector((store) => store.auth)
      const dispatch = useDispatch()
  return (
    <Wrapper>
        <h1>Reset your password</h1>
        <p className='reset-password-desc'>Your password reset link will be sent to your email</p>
        <form>
        {resetPasswordSent && <div className='password-reset-msg'>
            <p>{passwordResetMessage}</p>
        </div>}
        
        {resetPasswordError && <AlertError message={errorMessage}/>}
            <label htmlFor="email">
                <h3>Email</h3>
                <input type="email" 
                required
                value={resetPasswordEmail}
            onChange={(e) =>
              dispatch(
                fillAuthInputs({
                  resetPasswordEmail: e.target.value,
                  type: 'reset-password',
                })
              )
            }
                />
            </label>
            <button type="button" className={`${resetPasswordLoad && 'btn-loading'}`}  onClick={() => dispatch(resetPassword({email: resetPasswordEmail}))}>
                Submit
            </button>
        </form>
        <Link to={'/login'}>
        <p className='back-btn'>Go back</p>
        </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100vw;
    padding-bottom: 40em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        color: var(--header-text-color);
        margin-top: 10rem;
    }

    .reset-password-desc {
        margin-top: 1rem;
        text-align: center;
        width: 80%;
    }

    .password-reset-msg {
        width: 100%;
        height: auto;
        max-height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2rem;
        background: #c6f6d5;
        color: var(--success-color);
        padding: 1rem;


        p {
            margin: 0;
            padding: 0;
            font-size: 1em;
            width: 100%;
            height: 100%;
            text-align: start;
            
        }
    }

    form {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    h3 {
        margin-top: 1.5rem;
        color: var(--text-color);

    }

    label {
        width: 100%;
    }

    input {
        width: 100%;
        height: 50px;
        padding: 0 2rem 0 1rem;
        margin-top: 0.5rem;
        border: solid 2px var(--stroke-color);
        font-size: 1em;
        outline: none;
    }

    button {
        width: 100%;
        height: 50px;
        display: grid;
        place-content: center;
        font-size: 1.2em;
        border: none;
        color: var(--white-color);
        margin-top: 1.5rem;
        background: var(--highlight-color);
    }

    .btn-loading {
        opacity: 0.8;
    }


    a {
        width: 90%;
    }

    .back-btn {
        width: 100%;
        text-align: end;
        text-decoration: underline;
        margin-top: 1rem;
    }

    @media only screen and (min-width: 768px) {
    form {
        width: 60%;
    }

    a {
        width: 60%;
    }
    }

    @media only screen and (min-width: 992px) {
        padding-bottom: 50em;
        
        form {
            width: 40%;
        }

        a {
        width: 40%;
    }
    }

`

export default ResetPasswordPage