import React from 'react'
import { Link} from 'react-router-dom';
import styled from 'styled-components'
import Obi from '../../assets/images/Only_Obi-BR.png'
import Shepe from '../../assets/images/Only_Shepe-BR.png'

const ServiceSection = () => {
  
  return (
    <Wrapper>
      <h1 className='title-label label-tag'>Services</h1>
      <div className="service-collection-container">
      <div className="service-collection courses">
        <div className="text-container">
          <h1 className='service-text-label label-tag'>Courses</h1>
          <p className='course-service-p'>Upskill with our engaging animation courses</p>
          <Link to={'/course'}>
          <button type="button">
            learn now
          </button>
          </Link>
        </div>
        <img src={Shepe} alt="Obi" loading='lazy' />
      </div>

      <div className="service-collection merch">
        <div className="text-container">
          <h1 className='service-text-label'>Merch Clothing</h1>
          <p>Shop our unique NFT cloths</p>
          <Link to={'/'}>
          <button type="button">
            currently unavilable..
          </button>
          </Link>
        </div>
        <img src={Obi} alt="Obi" loading='lazy' />
      </div>
      </div>
      
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
   
    .title-label {
      color: var(--header-text-color);
    }

    .service-collection {
      width: 80vw;
      height: 200px;
      background: var(--highlight-color);
      margin-top: 2.5rem;
      border: solid 2px grey;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
    }

    .courses {
      background: var(--secondary-color); 
    }

    .merch {
      color: rgb(255, 255, 255, 0.9);
    }

    img {
      height: 100%;
      width: 80px;
      object-fit: cover;
      margin-top: 5rem;
      z-index: 10;
    }

    .service-text-label {
      font-size: 1.5em;
    }

    p {
      font-size: 0.9em;
      margin-top: 0.5rem;
    }

    .course-service-p {
      color: var(--text-color);
    }

    .label-tag {
      color: var(--header-text-color);
    }

    button {
    background: var(--primary-color);
    color: var(--secondary-color);
    height: 30px;
    border: none;
    width: auto;
    padding: 1rem;
    display: grid;
    place-content: center;
    margin-top: 1rem;
    cursor: pointer;
    }


@media only screen and (min-width: 992px) {

  .title-label {
    margin-top: 2rem;
  }

  .service-collection-container {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
  }

  .service-collection  {
    width: 100%;
    height: 300px;
    position: relative;
  }
  
  p {
    width: 90%;
  }

  img {
    position: absolute;
    width: auto;
    right: -12%;
  }

}


`

export default ServiceSection