import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {URL} from '../paths/url'

import Cookies from 'js-cookie'

const initialState = {
    // profile 
    isAdmin: false,
    errorMessage: '',
    isError: false,
    isLoading: false,
    user: {},
    myCourses: [],

    // my orders 
    myOrders: [],
    myOrdersLoad: false,
    myOrdersError: false,
    myOrdersErrorMsg: '',

    // user workflow
    cart: Cookies.get('cart') && true,

}

export const getProfile = createAsyncThunk(
    'actions/getProfile',
    async (payload, thunkAPI) => {
    //   const { email, password } = payload
      try {
        const resp = await axios.get(
          `${URL}/api/v1/user/myProfile`, 
          {
            withCredentials: true,
          }
        )
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const getMyOrders = createAsyncThunk(
    'actions/getMyOrders',
    async (payload, thunkAPI) => {

      try {
        const resp = await axios.get(
          `${URL}/api/v1/order/myOrders`, 
          {
            withCredentials: true,
          }
        )
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // toggleCartPopup: (state, action) => {
        //     state.cartPopupOpen = !state.cartPopupOpen
        //     console.log(state.cartPopupOpen);
            
        // }
    },
    extraReducers(builder) {
        builder
        .addCase(getProfile.pending, (state, action) => {
          state.isLoading = true
          state.isError = false
        })
        .addCase(getProfile.fulfilled, (state, action) => {
            const { status, response} = action.payload
            if (status === 'success') {
                state.isLoading = false
                state.user = response.user || {}
                state.isAdmin = response.user.isAdmin || false
 
                if (Array.isArray(response.user.myCourses) && response.user.myCourses.length > 0) {
                  state.myCourses = response.user.myCourses
                }


            } else {
                state.isLoading = false
                state.isError = true
                state.errorMessage = response.msg                
            }
        })
        .addCase(getProfile.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.errorMessage = 'Network error'
        })

        // get my orders 

        .addCase(getMyOrders.pending, (state, action) => {
          state.myOrdersLoad = true
          state.myOrdersError = false
        })
        .addCase(getMyOrders.fulfilled, (state, action) => {
            const { status, response} = action.payload
            if (status === 'success') {
                state.myOrdersLoad = false
                state.myOrdersError = false
                state.myOrders = response.order
            } else {
                state.myOrdersLoad = false
                state.myOrdersError = true
                state.myOrdersErrorMsg = response.msg                
            }
        })
        .addCase(getMyOrders.rejected, (state, action) => {
          state.myOrdersLoad = false
          state.myOrdersError = true
          state.errorMessage = 'Network error'
        })
    }
})

export default authSlice.reducer
export const {
} = authSlice.actions