import React from 'react'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement, 
  Title,
  Tooltip,
  Legend
  } from 'chart.js';
  
  // Register necessary Chart.js components
  ChartJS.register(
    CategoryScale,
  LinearScale,
  BarElement,  
  Title,
  Tooltip,
  Legend
  );

  const getMonthName = (monthNumber) => {
    const months = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
    return months[parseInt(monthNumber, 10) - 1];
  };

const SalesGraph = ({partition}) => {
    const labels = partition.map(entry => getMonthName(entry.month));
    const totalEarningsData = partition.map(item => item.totalEarnings); 
    const totalMoneyInData = partition.map(item => item.totalMoneyIn); 

    const data = {
        labels, // X-axis labels (month names)
        datasets: [
          {
            label: 'Total Earnings',
            data: totalEarningsData,
            backgroundColor: '#674636',
          },
          {
            label: 'Total Money In',
            data: totalMoneyInData,
            backgroundColor: '#FFF5E4',
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Monthly Earnings and Money In',
        },
        },
        scales: {
        y: {
            beginAtZero: true,
        },
        },
  };
  return (
    <Wrapper>
        <Bar data={data} options={options} className='bar'/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .bar {
        width: 100%;
        height: 100%;
    }
`
export default SalesGraph