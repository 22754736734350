import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CourseLoadSkeleton = () => {
  return (
    <Wrapper>
        <Skeleton height={150}/>
        <div className="info-wrapper">
            <div className="details-container">
                <div className="title">
                    <Skeleton height={20} containerClassName="flex-1"/>
                </div>
            </div>
            <div className="description">
                <Skeleton height={40}/>
            </div>
            <div className="btn">
                <Skeleton height={50}/>
            </div>
        </div>
        
    </Wrapper>
  )
}

const Wrapper = styled.div`
    margin-top: 2rem;
    width: 100%;
    height: auto;
    border: solid 1px var(--stroke-color);


    .info-wrapper {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .details-container {
        width: 100%;
        /* display: flex; */
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .description {
        margin-top: 1rem;
        width: 100%;
    }

    .btn {
        margin-top: 1rem;
        width: 100%;
    }
   
`
export default CourseLoadSkeleton