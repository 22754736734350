import React from 'react'
import styled from 'styled-components'

const MerchPage = () => {
  return (
    <Wrapper>
        <h1>Merch</h1>
    </Wrapper>
  )
}

const Wrapper = styled.div`
     width: 100vw;
    padding-bottom: 35em;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-top: 8rem;
    }
`
export default MerchPage