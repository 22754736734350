import { configureStore } from '@reduxjs/toolkit'
import eventSlice from '../slices/eventSlice'
import authSlice from '../slices/authSlice'
import courseSlice from '../slices/courseSlice'
import cartSlice from '../slices/cartSlice'
import userSlice from '../slices/userSlice'
import uploadSlice from '../slices/uploadSlice'
import checkoutSlice from '../slices/checkoutSlice'
import dashboardSlice from '../slices/dashboardSlice'
import waitlistSlice from '../slices/waitlistSlice'
import productSlice from '../slices/productSlice'
import profileSlice from '../slices/profileSlice'


export const store = configureStore({
    reducer: {
        event: eventSlice,
        auth: authSlice,
        course: courseSlice,
        cart: cartSlice,
        user: userSlice,
        upload: uploadSlice,
        checkout: checkoutSlice,
        dashboard: dashboardSlice,
        waitlist: waitlistSlice,
        product: productSlice,
        profile: profileSlice,

    }
})