import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {standardNumber, standardRate} from '../../utils/utilsFunctions'

const OrderItem = ({imageUrl, name, price}) => {
    const { selectedRate, rates, conversionRate} = useSelector((store) => store.event);
    const getRateSchema = rates.find(val => val.code === selectedRate)
    const {icon} = getRateSchema
  return (
    <Wrapper>
        <img src={imageUrl} alt={name} />
        <div className="order-desc-container">
            <h3>{name}</h3>
            <p>{icon}{standardNumber(standardRate(price, conversionRate))}</p>
         </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1rem;

        img {
        height: 70px;
        width: 100px;
        object-fit: cover;
        margin-right: 1rem;
        }

        .order-desc-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
`
export default OrderItem