// currency rates 
export const ratesData = [
    {
      id: 0,
      code: 'NGN',
      icon: '₦',
      name: 'Naira',
    },
    {
      id: 1,
      code: 'GHS',
      icon: '¢',
      name: 'Cedis',
    },
    {
      id: 2,
      code: 'USD',
      icon: '$',
      name: 'Dollar',
    },
  ]


// convert num to standard format
export const standardNumber = (number) => {
    return new Intl.NumberFormat().format(number.toFixed(2))
 }


const navMenuData = [{
    id: 0,
    route: '/dashboard',
    title: 'dashboard'
}, 
{
    id: 1,
    route: '/',
    title: 'home'
}, 
{
    id: 2,
    route: '/course',
    title: 'courses'
}, 
{
    id: 3,
    route: '/merch',
    title: 'merch (sold out)'
}, 
{
    id: 4,
    route: '/login',
    title: 'log in or create account'
}, 
]

// naviagation routes logic
export const navMenuRoutes = (user, isLargeScreen) => {
    let newNavMenuData = [...navMenuData]
        
    if (isLargeScreen) {
        newNavMenuData = newNavMenuData.filter(val => val.route !== '/login');
    }

    // remove dashboard if user is not loggedin
    
    if (!user) {
        newNavMenuData = newNavMenuData.filter(val => val.title !== 'dashboard')
    } else {
        const {_id, isAdmin} = user || {}

        // modify login or create account to profile 
        newNavMenuData = newNavMenuData.map((values) => {
            if (_id && values.id === 4) {
                return {
                    ...values,
                    route: '/profile',
                    title: 'profile'
                }
            }
            return values
        })

        // remove dashboard if user is not admin 

        if (!isAdmin) {
            newNavMenuData = newNavMenuData.filter(val => val.title !== 'dashboard')
        } 
    }

    return newNavMenuData
}



// convert string to initial capital letter 
export const initialCaps = (str) => {
    return Array(str).map(s => s[0].toUpperCase() + s.substring(1)).join('')
}

// convert rates 
export const standardRate = (num, rate) => {
    const value = num * rate
    return parseFloat(value.toFixed(2))
}





