
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  verifyPayment,
  handleCloseOnFinishedOrder,
  cancelInitiatedOrder,
} from '../../slices/checkoutSlice';
import Loader from '../../helpers/Loader';
import { FaCheckCircle } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';
import { standardNumber, standardRate } from '../../utils/utilsFunctions';

const PaymentPopover = () => {
  const {
    orderId,
    paymentInitiated,
    paymentConfirmationLoad,
    paymentError,
    paymentErrorMsg,
    paymentTrue,
    orderDetails,
    coursePurchaseVideoDetails,
    paymentCompleted,
    paymentRejected
  } = useSelector((store) => store.checkout);

  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedRate, rates, conversionRate } = useSelector((store) => store.event);
  const getRateSchema = rates.find((val) => val.code === selectedRate);
  const { icon } = getRateSchema || {};

  const query = new URLSearchParams(location.search);
  const reference = query.get('reference');

  // Trigger payment verification
  useEffect(() => {
    if (paymentInitiated && reference && !paymentRejected) {
      let hasVerified = false;

      if (!hasVerified) {
        dispatch(verifyPayment({ id: orderId, reference }));
        hasVerified = true
      }
      
    }
  }, [paymentInitiated, reference, paymentRejected,dispatch, orderId]);

  useEffect(() => {
    if (paymentInitiated && paymentRejected && reference) {
      let hasVerified = false;

      if (!hasVerified) {
        dispatch(verifyPayment({ id: orderId, reference }));
        hasVerified = true
      }
    }
  }, [paymentInitiated, paymentRejected, reference, dispatch, orderId]);

  const { total = 0, email, completionID, cart = [] } = orderDetails || {};

  // Helper function to render order details safely
  const RenderOrderDetails = () => {
    if (Array.isArray(coursePurchaseVideoDetails) && coursePurchaseVideoDetails.length > 0) {
      return coursePurchaseVideoDetails.map((values) => {
        const { course_name, videoUrl, _id } = values;
        return (
          <div className="order-label" key={_id}>
            <p>{course_name}</p>
            <a href={videoUrl}>Access course</a>
          </div>
        );
      });
    } else if (Array.isArray(cart) && cart.length > 0) {
      return cart.map((value) => {
        const { itemName } = value;
        return (
          <div className="order-label" key={value.id}>
            <p>{itemName}</p>
          </div>
        );
      });
    }
    return <p>No items in your order</p>;
  };

  return (
    <Wrapper paymentInitiated={paymentInitiated}>
      {paymentInitiated && !paymentCompleted && !paymentError ? (
        <div className="payment-load">
          <h1>Payment Initialized</h1>
          <div className="loader-container">
            <Loader />
            <p>{paymentConfirmationLoad ? 'Confirming payment...' : 'Waiting for payment...'}</p>
          </div>
          <button
            type="button"
            className="btn-cancel"
            onClick={() => dispatch(cancelInitiatedOrder())}
          >
            Cancel
          </button>
        </div>
      ) : paymentCompleted && paymentTrue ? (
        <div className="payment-success">
          <FaCheckCircle className="confirmed-icon" />
          <h1>Payment Successful</h1>
          <h3 className="price">
            {icon}
            {standardNumber(standardRate(total, conversionRate))}
          </h3>
          <p className="trans-ref">
            Transaction Number: <strong>{completionID}</strong>
          </p>
          <div className="payment-success-details">
            <h3>Your order has been sent to your email</h3>
            <p>{email}</p>
          </div>
          <div className="order-summary">
            <p>
              <strong>Order summary:</strong>
            </p>
            <div className="order-list">
              <RenderOrderDetails />
            </div>
          </div>
          <div className="btn-container">
            <button type="button" onClick={() => dispatch(handleCloseOnFinishedOrder())}>
              Done
            </button>
          </div>
        </div>
      ) : paymentError || !paymentTrue ? (
        <div className="payment-failed">
          <MdCancel className="failed-icon" />
          <h1>Payment Failed</h1>
          <p>{paymentError ? paymentErrorMsg : 'Payment verification failed'}</p>
          <button type="button" onClick={() => dispatch(handleCloseOnFinishedOrder())}>
            Back to checkout
          </button>
        </div>
      ) : null}
    </Wrapper>
  );
};




const Wrapper = styled.div`
 position: fixed;
top: 0;
left: 0;
display: flex;
flex-direction: column;
align-items: center;
background: var(--background-color);
color: var(--color-text-primary);
height: 100vh;
width: 100vw;
transform: ${({  paymentInitiated }) => ( paymentInitiated ? 'translateX(0)' : 'translateX(100%)')};
opacity: ${({  paymentInitiated }) => ( paymentInitiated ? 1 : 0)};
z-index: 2000;




/* payment load  */

   .payment-load {
     height: 100%;
    width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;

     .loader-container {
     margin-top: 5rem;
     display: flex;
     flex-direction: column;
     align-items: center;

     p {
         margin-top: 2rem;
     }

   }

   .btn-cancel {
     margin-top: 3rem;
     width: 70%;
     height: 50px;
     border: solid 1px var(--stroke-color);
     background: var(--error-color);
     color: var(--white-color);
     font-size: 1.1em;
     }

   }

   /* payment sucess  */


     .payment-success {
        height: 100%;
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;

     .confirmed-icon {
         font-size: 4em;
         color: var(--success-color);
     }

     h1 {
         margin-top: 1rem;
     }

     .price {
         margin-top: 1rem;
         font-size: 1.5em;
     }

     .trans-ref {
         margin-top: 0.5rem;
         opacity: 0.8;
     }

     .payment-success-details {
         width: 80%;
         display: flex;
         flex-direction: column;
         align-items: center;
         margin-top: 1rem;

         p {
             margin-top: 0.1rem;
             opacity: 0.8;
         }
     }

     .order-summary {
         width: 80%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         margin-top: 1rem;
 
         .order-list {
             margin-top: 1rem;
             width: 100%;
             display: flex;
             flex-direction: column;
             align-items: flex-start;
             /* justify-content: space-between; */
         }

         a {
             text-decoration: underline;
         }
     }

     .order-label {
      margin-top: 1rem;
     }

     .btn-container {
         margin-top: 2rem;
         width: 80%;
         display: flex;
         flex-direction: column;
         align-items: center;

         button {
             width: 100%;
             height: 50px;
             margin-top: 1rem;
             font-size: 1.1em;
             border: solid 1px var(--stroke-color);
             background: none;
         }

         button:hover {
             background: var(--stroke-color);
             color: var(--white-color);
         }
     }
     }

     /* payment failed  */

     .payment-failed {
     height: 100%;
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;

     .failed-icon {
         font-size: 4em;
         color: var(--error-color);
     }

     h1 {
         margin-top: 1rem;
     }

     .price {
         margin-top: 1rem;
         font-size: 1.5em;
     }

     p {
         margin-top: 1rem;
         width: 60%;
         text-align: center;
         opacity: 0.8;
     }

     button {
         width: 80%;
         height: 50px;
         margin-top: 2rem;
         font-size: 1.1em;
         border: solid 1px var(--stroke-color);
         background: none;
         }
 }

 @media only screen and (min-width: 600px) {
    .payment-load {
        .btn-cancel {
            width: 50%;
        }
    }

    .payment-success {
        .btn-container {
            width: 50%;
        }

        .order-summary {
          width: 50%;
        }
    }

    .payment-failed {
      button {
        width: 50%;
      }
    }
 }

 @media only screen and (min-width: 768px) {
    .payment-load {
        .btn-cancel {
            width: 50%;
        }
    }

    .payment-success {
        .btn-container {
            width: 50%;
        }

        .order-summary {
          width: 50%;
        }
    }

    .payment-failed {
      button {
        width: 50%;
      }
    }
 }

 @media only screen and (min-width: 992px) {
    .payment-load {
        .btn-cancel {
            width: 30%;
        }
    }

    .payment-success {
        .btn-container {
            width: 30%;
        }

        .order-summary {
          width: 30%;
        }
    }

    .payment-failed {
      button {
        width: 30%;
      }
    }
 }

`;

export default PaymentPopover;
