import React from 'react'
import {AlertSuccess, AlertError} from '../helpers'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link} from 'react-router-dom';
import {fillAuthInputs, signup} from '../slices/authSlice'



const RegisterPage = () => {
    const {
        signupInputs: {
            firstname,
            lastname,
            email,
            password
        },
        errorMessage,
        signupLoad,
        signupError,
      } = useSelector((store) => store.auth)
      const dispatch = useDispatch()
  return (
    <Wrapper>
        <h1>Create Account</h1>
        <form>
        {signupError && <AlertError message={errorMessage}/>}
            <label htmlFor="first name">
                <h3>First Name</h3>
                <input type="text" 
                required
                value={firstname}
            onChange={(e) =>
              dispatch(
                fillAuthInputs({
                  firstname: e.target.value,
                  lastname,
                  email,
                  password,
                  type: 'signup',
                })
              )
            }
                />
            </label>
            <label htmlFor="last name">
                <h3>Last Name</h3>
                <input type="text" 
                required
                value={lastname}
            onChange={(e) =>
              dispatch(
                fillAuthInputs({
                  lastname: e.target.value,
                  firstname,
                  email,
                  password,
                  type: 'signup',
                })
              )
            }
                />
            </label>
            <label htmlFor="email">
                <h3>Email</h3>
                <input type="email" 
                required
                value={email}
            onChange={(e) =>
              dispatch(
                fillAuthInputs({
                  email: e.target.value,
                  firstname,
                  lastname,
                  password,
                  type: 'signup',
                })
              )
            }
                />
            </label>
            <label htmlFor="password">
                <h3>Password</h3>
                <input type="password" 
                required
                value={password}
            onChange={(e) =>
              dispatch(
                fillAuthInputs({
                  password: e.target.value,
                  firstname,
                  lastname,
                  email,
                  type: 'signup',
                })
              )
            }
                />
            </label>
            
            <button type="button" className={`create-btn ${signupLoad ? 'btn-loading' : ''}`} onClick={() => dispatch(signup({firstname, lastname, email, password}))}>
                Create
            </button>
        </form>
        <Link to={'/login'}>
        <p className='back-btn'>Go back</p>
        </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
      width: 100vw;
    padding-bottom: 35em;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        color: var(--header-text-color);
        margin-top: 8rem;
    }

    form {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
    }


    h3 {
        margin-top: 1rem;
        color: var(--text-color);

    }

    label {
        width: 100%;
    }

    a {
        width: 90%;
    }

    
    input {
            width: 100%;
            height: 50px;
            padding: 0 2rem 0 1rem;
            margin-top: 0.5rem;
            border: solid 2px var(--stroke-color);
            font-size: 1em;
            outline: none;
    }

    button {
        width: 100%;
        height: 50px;
        display: grid;
        place-content: center;
        font-size: 1.2em;
        border: none;
        color: var(--white-color);
        margin-top: 3rem;
        background: var(--highlight-color);
    }

    .btn-loading {
        opacity: 0.8;
    }

    .back-btn {
        width: 100%;
        text-align: end;
        text-decoration: underline;
        margin-top: 1rem;
    }

    



    @media only screen and (min-width: 768px) {
    form {
        width: 60%;
    }

    a {
      width: 60%;
    }
}

@media only screen and (min-width: 992px) {
  padding-bottom: 50em;

    form {
        width: 40%;
    }

    a {
      width: 40%;
    }
}

`

export default RegisterPage