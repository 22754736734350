import React from 'react'
import styled from 'styled-components'

const LoadingPage = () => {
  return (
    <Wrapper>
        <h1>Loading...</h1>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: var(--primary-color);

    h1 {
      color: #ffffff;
    }
`

export default LoadingPage