import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link} from 'react-router-dom';
import styled from 'styled-components'
import LastProductSold from './LastProductSold'
import SalesGraph from './SalesGraph'
import {getSalesReport, getSalesReportByDate} from '../../slices/dashboardSlice'
import {standardNumber, standardRate} from '../../utils/utilsFunctions'
import {Loader} from '../../helpers'




const AdminSection = () => {
  const [getYears, setGetYears] = useState([])

    const { 
      isLoading,
      isError,
      errorMessage,
      salesReport,
      salesReportByDate,
      defaultYear
    } = useSelector((store) => store.dashboard);
    const { selectedRate, rates, conversionRate} = useSelector((store) => store.event);
    const getRateSchema = rates?.find(val => val.code === selectedRate)
    const {icon} = getRateSchema || '' 
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(getSalesReport())
    }, [dispatch]);

    useEffect(() => {
      if (salesReport) {
        dispatch(getSalesReportByDate({date: defaultYear}))
      }
    }, [salesReport, defaultYear, dispatch]);

    useEffect(() => {
      if (salesReportByDate) {
        setGetYears([...new Set(salesReportByDate.map(val => val.year))]);
      }
   }, [salesReportByDate]);

    const {totalEarnings, totalMerchSold, totalCoursesSold, lastProductSoldReport} = salesReport || {}



    
  return (
    <Wrapper>
        <h1>Dashboard</h1>
        {isLoading ? <div className='loader-container'>
          <Loader/>
        </div> : 
        <>
        <div className="sales-summary-header">
          <h3>Sales Report</h3>
          <select>
            {getYears.map((year, index) => {
                return <option value={year || new Date().getFullYear()} key={index}>{year || new Date().getFullYear()}</option>
            })}
          </select>
        </div>
        <div className="dashboard-container">
          <SalesGraph partition={salesReportByDate}/>
        </div>
       
      <div className="total-account-summary-container ">
        <div className="account-summary highlight">
          <p>Total Earnings</p>
          <h3>{icon}{standardNumber(standardRate(totalEarnings || 0, conversionRate))}</h3>
        </div>
        <div className="account-summary">
          <p>Total Courses Sold</p>
          <h3>{totalCoursesSold || 0}</h3>
        </div>
        <div className="account-summary highlight">
          <p>Total Merch Sold</p>
          <h3>{totalMerchSold || 0}</h3>
        </div>
      </div>

      <div className="transaction-container">

        <div className="nav-menu-container">
          <h3>Last Product Sold</h3>
        </div>

        <div className="sales-report-container">

          {Array.isArray(lastProductSoldReport) && lastProductSoldReport.length < 1 
          ? 
          <div className='empty-orders'>
              <p>You don't have any order currently!</p>
          </div> 
          : 
          <div className="last-products-sold-container">
            {Array.isArray(lastProductSoldReport) && lastProductSoldReport.map((sales) => {
                return <LastProductSold {...sales} key={sales._id}/>
              })}
            </div>}
          
        </div>
      </div>
        </>
      }
        

    </Wrapper>
  )
}

const Wrapper = styled.div`
    margin-top: 8rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .loader-container {
      margin-top: 3rem;
    }

    .sales-summary-header {
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
    }

    select {
      height: 50px;
      width: auto;
      color: var(--stroke-color);
      padding: 0.5rem;
      outline: none;
      cursor: pointer;
      width: auto;
    }

    .dashboard-container {
      width: 80%;
      height: 400px;
      margin-top: 2rem;
    }

    .greetings-container {
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 2rem;
    }

    .total-account-summary-container {
      margin-top: 1.5rem;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .account-summary {
      width: 100%;
      height: auto;
      padding: 1rem;
      margin-top: 1rem;
      background: var(--secondary-color);
      color: var(--text-color);
      border: solid 1px var(--stroke-color);
      border-radius: 5px;

      h3 {
        font-size: 2em;
        margin-top: 0.5rem;
      }
    }

    .highlight {
      background: var(--highlight-color);
      color: var(--white-color);
    }

    .transaction-container {
      margin-top: 2rem;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .nav-menu-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      user-select: none;
    }

    .sales-report-container {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .last-products-sold-container {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .last-order-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .empty-orders {
      margin-top: 1rem;
      opacity: 0.8;
    }

    @media only screen and (min-width: 768px) {
      .total-account-summary-container  {
            display: grid;
            place-items: initial;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-template-rows: subgrid;
            gap: 1rem;
            margin-top: 1rem;
      }
      .account-summary  {
        margin-top: 0rem;
        height: 120px;
      }
    }

    @media only screen and (min-width: 992px) {
      padding-bottom: 5rem;

      .sales-summary-header {
      width: 70%;
      }

      .dashboard-container {
        width: 70%;
      }

      .total-account-summary-container  {
            width: 70%;
      }

      .account-summary  {
        margin-top: 1rem;
        height: 150px;
      }

      .nav-menu-container {
        width: 70%;
        margin-top: 1rem;
      }

      .last-products-sold-container {
        width: 70%;
      }
    }
`
export default AdminSection