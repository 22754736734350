import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { PiWhatsappLogoFill } from "react-icons/pi";

const Support = () => {
    const [displayIcon, setDisplayIcon] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        window.addEventListener('scroll', () => {
      if (scrollPosition >= 50) {
        setDisplayIcon(true)
      } else {
        setDisplayIcon(false)
      }
    })

    }
    
  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };

    
  }, [displayIcon])

  return (
    <Wrapper style={{opacity: `${displayIcon ? 1: 0}`}}>
        <a href="http://wa.me/2349122850664" className="support-container">
            <div className="support-text">
                <p>Looking for help? Chat with us!</p>
            </div>
            <div className="icon-container">
                <PiWhatsappLogoFill className='icon'/> 
            </div>
        </a>
    </Wrapper>
  )
}


const Wrapper = styled.div`
    width: 100%;
    position: fixed;
    height: 50px;
    max-height: 50px;
    bottom: 10%;
    z-index: 500;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 1.1em;
    opacity: 0.9;
    transition: 0.5s all;

    .support-container {
        width: auto;
        height: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;    
    }

    .support-text {
        height: 100%;
        background: var(--white-color);
        display: grid;
        place-content: center;
        padding: 1rem;
        font-size: 0.8em;
        margin-right: 1rem;
    }

    .icon-container {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        padding: 1rem;
        display: grid;
        place-content: center;
        background: var(--primary-color);

        .icon {
            font-size: 2.5em;
            color: var(--white-color);
        }
    }


   
`
export default Support