import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch} from 'react-redux'
import {Loader} from '../../helpers'
import {getMyOrders} from '../../slices/userSlice'


const ProfileOrders = () => {
  
  return (
    <Wrapper>
      <h3>Orders</h3>
    <div className="order-list-container">
        <div className="order-list">
          {/* orders goes here  */}
        </div>
    </div>


    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loader-container {
        margin-top: 3rem;
    }

    .empty-container {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .empty-btn {
            margin-top: 1rem;
            padding: 1rem;
            display: grid;
            place-content: center;
            height: 40px;
            width: 100%;
            border: solid 1px var(--stroke-color);
            background: none;
            color: var(--stroke-color);
            font-size: 0.9em;
        }
    }

    .error-container {
        margin-top: 2rem;
        color: var(--error-color);
    }

    .order-list-container {
      width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .order-list {
        margin-top: 2rem;
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0.5rem;
    }
`

export default ProfileOrders