import React, {useEffect} from 'react'
import styled from 'styled-components'
import SingleCartItem from './SingleCartItem'
import { useLocation, Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {standardNumber, standardRate} from '../../utils/utilsFunctions'
import {Loader} from '../../helpers'
import {getCart} from '../../slices/cartSlice'

import { IoAddCircleSharp } from "react-icons/io5";
import { FiChevronLeft } from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { GoPlus} from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import {toggleCartPopup} from '../../slices/cartSlice'

// import {toggleCourseModal} from '../../slices/courseSlice'

const CartSection = () => {

  const { courseModalOpen } = useSelector((store) => store.course);
  const { cartPopupOpen,
    getCartLoad,
    isCartError,
    errorMessage,
    successMessage,
    cart,
    cartItems,
  } = useSelector((store) => store.cart);
  const { selectedRate, rates, conversionRate} = useSelector((store) => store.event);
    const getRateSchema = rates.find(val => val.code === selectedRate)
    const {icon} = getRateSchema
    const dispatch = useDispatch()
    const location = useLocation()
    const currentPath = location.pathname.toString().substring(1)

    useEffect(() => {
      dispatch(getCart())
    }, [dispatch]);

    const {subtotal, shippingFee, total} = cart || {}


  return (
    <Wrapper>
      <h1 className='cart-title-label'>Cart</h1>
      <div className="route-container">
        <div>
                <Link to={'/'}>home </Link>
                / {currentPath}
            </div>
      </div>

      {
  getCartLoad && cartItems.length === 0 ? (
    <div className="cart-load">
      <Loader />
    </div>
  ) : cartItems.length > 0 && !isCartError ? (
    <>
      <div className="cart-parent-container">
        {cartItems.map((values) => {
          return <SingleCartItem {...values} key={values._id} />;
        })}
      </div>
      <div className="price-summary">
        <div className="sub-total-container">
          <p>Subtotal</p>
          <p>
            {icon}
            {standardNumber(standardRate(subtotal, conversionRate)) || 'loading..'}
          </p>
        </div>
        <div className="shipping-container">
          <p>Charges</p>
          <p>
            {icon}
            {standardNumber(standardRate(shippingFee, conversionRate)) || 'loading..'}
          </p>
        </div>
        <div className="total-container">
          <h3>Total</h3>
          <h3>
            {icon}
            {standardNumber(standardRate(total, conversionRate)) || 'loading..'}
          </h3>
        </div>
      </div>
      <div className="btn-container">
        <Link to={"/course-checkout"}>
          <button type="button" className="checkout-btn">
            Checkout
          </button>
        </Link>
      </div>
    </>
  ) : (
    <div className="empty-cart-container">
      <h3>Ooppss...Your cart is currently empty</h3>
      <Link to={"/course"}>
        <button type="button" className="empty-btn">
          Continue Shopping
        </button>
      </Link>
    </div>
  )
}

    </Wrapper>
  )
}

const Wrapper = styled.div`
   margin-top: 8rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: var(--header-text-color);
    }

    .route-container {
        margin-top: 1.5rem;
        width: 90%;
        opacity: 0.8;
    }

    .cart-load {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .empty-cart-container {
    margin-top: 5rem;
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      width: 80%;
    }
  }


    .empty-btn {
        margin-top: 1.5rem;
        height: 50px;
        width: 100%;
        display: grid;
        place-content: center;
        font-size: 1.1em;
        border: none;
        color: var(--white-color);
        background: var(--highlight-color);

    }

    h3 {
      font-size: 1.1em;
    }

    p {
      font-size: 0.9em;
    }

    .cart-parent-container {
      margin-top: 2rem;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }


    
 

  .price-summary {
        width: 90%;
        display: grid;
        grid-template-columns: auto;
        gap: 1rem;
        margin-top: 3rem;

        >* {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: -0.5rem;
        }


  }


.btn-container {
  margin-top: 2rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    width: 100%;
  }

  button {
    margin-top: 1rem;
    width: 100%;
    height: 50px;
    font-size: 1.1em;
  }
 
}

.view-cart-btn {
  background: var(--primary-color);
  color: var(--secondary-color);
  border: solid 2px var(--secondary-color);
}

.checkout-btn {
  background: var(--highlight-color);
  color: var(--white-color);
  border: none;
}

@media only screen and (min-width: 768px) {
  .btn-container{
      width: 50%;
    }

    .empty-cart-container {
    a {
      width: 50%;
    }
  }
}

@media only screen and (min-width: 992px) {
  .btn-container{
      width: 30%;
    }

    .empty-cart-container {
    a {
      width: 30%;
    }
  }
}
`


export default CartSection