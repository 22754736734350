import React from 'react'
import styled from 'styled-components'

const Loader = () => {
  return (
    // <div className='loader'></div>
    <Wrapper></Wrapper>
  )
}

const Wrapper = styled.div`
   width: 40px;
  aspect-ratio: 1;
  --c: linear-gradient(var(--stroke-color) 0 0);
  --r1: radial-gradient(farthest-side at bottom,var(--stroke-color) 93%, var(--stroke-color));
  --r2: radial-gradient(farthest-side at top, var(--stroke-color) 93%, var(--stroke-color));
  background: 
    var(--c) ,var(--r1),var(--r2),
    var(--c) ,var(--r1),var(--r2),
    var(--c) ,var(--r1),var(--r2);
  background-repeat: no-repeat;
  animation: l1 1s infinite alternate;

  @keyframes l1 {
  0%,10% {
    background-size: 8px 0,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(50% - 2px),0 calc(50% + 2px),50% 50%,50% calc(50% - 2px),50% calc(50% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
 }
 90%,100% {
    background-size: 8px 100%,8px 4px, 8px 4px;
    background-position: 0 50%,0 -2px,0 calc(100% + 2px),50% 50%,50% -2px,50% calc(100% + 2px),100% 50%,100% -2px,100% calc(100% + 2px);
 }
}

`
export default Loader

