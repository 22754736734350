import React from 'react'
import styled from 'styled-components'
import { Link} from 'react-router-dom';


const ErrorPage = () => {
  return (
    <Wrapper>
      <h1>Error Page</h1>
      <div className="route-container">
            <div>
                <Link to={'/'}>home </Link>
                / 404 
            </div>
        </div>
      <p>You seem to be lost</p>

      <Link to={'/'} className='btn-link'>
      <button type="button">Go back</button>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100vw;
    padding-bottom: 40em;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-top: 8rem;
        color: var(--header-text-color);
    }

    .route-container {
        margin-top: 3rem;
        width: 90%;
        opacity: 0.8;
    }


    p {
      margin-top: 4rem;
    }

    .btn-link {
      width: 50%;
    }

    button {
      height: 50px;
      width: 100%;
      border: solid 1px var(--stroke-color);
      background: none;
      color: var(--stroke-color);
      font-size: 0.9em;
      margin-top: 1rem;
    }

    

  

  @media only screen and (min-width: 600px) {
    .route-container {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
  }


  @media only screen and (min-width: 768px) {
      .btn-link {
        width: 30%;
      }
  }

  @media only screen and (min-width: 992px) {
    padding-bottom: 50em;
    
}



`
export default ErrorPage