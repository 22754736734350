import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {URL} from '../paths/url'

const storedCartItems = () => {
  const checkoutInputs = localStorage.getItem('storedCart');
      if (checkoutInputs) {
        return JSON.parse(localStorage.getItem('storedCart'))
      } else {
        return []
      }
}


const initialState = {
    cartPopupOpen: false,
    getCartLoad: false,
    addCartLoad: false,
    updateCartLoad: false,
    deleteCartLoad: false,
    isCartError: false,
    selectedElemInd: null,  
    errorMessage: '',
    successMessage: '', 
    cart: {},
    cartItems: storedCartItems(),
    quantity: 1 
}

export const getCart = createAsyncThunk(
    'actions/getCart',
    async (payload, thunkAPI) => {
      try {
        const resp = await axios.get( 
          `${URL}/api/v1/cart`, 
          {
            withCredentials: true, 
          }
        )
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const addCartItem = createAsyncThunk(
    'actions/addCartItem',
    async (payload, thunkAPI) => {
        const {type, merchId, courseId, variant, quantity, elemInd} = payload
        thunkAPI.dispatch(getElementInd(elemInd))
        
        
      try {
        const resp = await axios.post(
          `${URL}/api/v1/cart/addItem`, {
            type,
            merchId,
            courseId,
            variant,
            quantity
          },
          {
            withCredentials: true,
          }
        )
        thunkAPI.dispatch(getCart())
        return { response: resp.data, status: 'success' }
      } catch (error) {
        
        return {
          response: error.response,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const updateCartItem = createAsyncThunk(
    'actions/updateCartItem',
    async (payload, thunkAPI) => {
        const {id, quantity} = payload
      try {
        const resp = await axios.patch(
          `${URL}/api/v1/cart/updateCartItem/${id}`, {
            quantity
          },
          {
            withCredentials: true,
          }
        )
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const deleteCartItem = createAsyncThunk(
    'actions/deleteCartItem',
    async (payload, thunkAPI) => {
      const {id} = payload
      
      
      try {
        const resp = await axios.delete(
          `${URL}/api/v1/cart/deleteCartItem/${id}`, 
          { 
            withCredentials: true,
          }
        )
        thunkAPI.dispatch(getCart())
        return { response: resp.data, status: 'success' }
      } catch (error) {        
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

 
const authSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        toggleCartPopup: (state, action) => {
            state.cartPopupOpen = !state.cartPopupOpen            
        },
        changeCartQuantity: (state, action) => {
            state.quantity = action.payload
        },
        getElementInd: (state, action) => {
          state.selectedElemInd = action.payload
        }
    },
    extraReducers(builder){
        builder
        // get cart 
        .addCase(getCart.pending, (state, action) => {
          state.getCartLoad = true
          state.isCartError = false

        }).addCase(getCart.fulfilled, (state, action) => {
          state.getCartLoad = true
          const {response, status} = action.payload
            if (status === 'success') {
              state.getCartLoad = false
              state.isCartError = false
              state.cart = response.cart
              state.cartItems = response.cart.items || []

              localStorage.setItem(
                'storedCart',
                JSON.stringify(state.cartItems)
              );
              
            } else {
              state.getCartLoad = false
              state.isCartError = true
              state.errorMessage = response.msg
            }
        }).addCase(getCart.rejected, (state, action) => {
          state.getCartLoad = true
          state.isCartError = true
        })
        // add cart item 
        .addCase(addCartItem.pending, (state, action) => {
            // state.selectedElemInd = action.payload  
            state.addCartLoad = true
            state.isCartError = false
        }).addCase(addCartItem.fulfilled, (state, action) => {
            state.addCartLoad = true
            const {response, status} = action.payload
            if (status === 'success') {
              state.addCartLoad = false
              state.successMessage = response.msg
              state.cartPopupOpen = true
            } else {
              state.addCartLoad = false
              state.isCartError = true
              state.errorMessage = response.msg
              
            }
        }).addCase(addCartItem.rejected, (state, action) => {
          state.addCartLoad = true
          state.isCartError = false
        })
        // update cart item 
        .addCase(updateCartItem.pending, (state, action) => {
          state.updateCartLoad = true
          state.isCartError = false
        }).addCase(updateCartItem.fulfilled, (state, action) => {
          state.updateCartLoad = true
          const {response, status} = action.payload
          if (status === 'success') {
            state.updateCartLoad = false
            state.isCartError = false
            state.cart = response.cart
          } else {
            state.updateCartLoad = false
            state.isCartError = true
            state.errorMessage = response.msg
          }
        }).addCase(updateCartItem.rejected, (state, action) => {
          state.updateCartLoad = true
          state.isCartError = false
        })
        // delete cart item 
        .addCase(deleteCartItem.pending, (state, action) => {
          state.deleteCartLoad = true
          state.isCartError = false
        }).addCase(deleteCartItem.fulfilled, (state, action) => {
          state.deleteCartLoad = true
          const {status, response} = action.payload
          if (status === 'success') {
            state.deleteCartLoad = false
            state.isCartError = false
            state.successMessage = response.msg
          } else {
            state.deleteCartLoad = false
            state.isCartError = true
            state.errorMessage = response.msg
          }
        }).addCase(deleteCartItem.rejected, (state, action) => {
          state.deleteCartLoad = true
          state.isCartError = false
        })
    }
})

export default authSlice.reducer
export const {
  toggleCartPopup,
  changeCartQuantity,
  getElementInd
} = authSlice.actions