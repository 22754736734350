import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {URL} from '../paths/url'

const initialState = {
   isLoading: false,
   isError: false,
   errorMessage: '',
   salesReport: {},
   salesReportByDate: [],
   defaultYear: ''

}

export const getSalesReport = createAsyncThunk(
    'actions/getSalesReport',
    async (payload, thunkAPI) => {
      try {
        const resp = await axios.get( 
          `${URL}/api/v1/sales/salesReport`, 
          {
            withCredentials: true, 
          }
        )
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const getSalesReportByDate = createAsyncThunk(
    'actions/getSalesReportByDate',
    async (payload, thunkAPI) => {
        const {date} = payload
      try {
        const resp = await axios.get( 
          `${URL}/api/v1/sales/salesReportByDate?select=${date}`, 
          {
            withCredentials: true, 
          }
        )
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )


const authSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        
    },
    extraReducers(builder){
        // get sales report 

        builder.addCase(getSalesReport.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
        })
        .addCase(getSalesReport.fulfilled, (state, action) => {
            state.isLoading = true
            const {response, status} = action.payload

            if (status === 'success') {
                // state.isLoading = false
                state.isError = false
                state.salesReport = response.report
                state.defaultYear = response.report.dateStamps.year

            } else {
                // state.isLoading = false
                state.isError = true
                state.errorMessage = response.msg
            }

        })
        .addCase(getSalesReport.rejected, (state, action) => {
            state.isLoading = true
            state.isError = true
        })

        // get sales report by date 
        builder.addCase(getSalesReportByDate.pending, (state, action) => {
            // state.isLoading = true
            state.isError = false
        })
        .addCase(getSalesReportByDate.fulfilled, (state, action) => {
            // state.isLoading = true
            const {response, status} = action.payload

            if (status === 'success') {
                state.isLoading = false
                state.isError = false
                state.salesReportByDate = response.partition
            } else {
                // state.isLoading = false
                state.isError = true
                state.errorMessage = response.msg
            }

        })
        .addCase(getSalesReportByDate.rejected, (state, action) => {
            state.isLoading = true
            state.isError = true
        })
    }
})

export default authSlice.reducer
export const {
    
} = authSlice.actions