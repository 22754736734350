import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch} from 'react-redux'
import {Loader} from '../../helpers'
import {getProfile} from '../../slices/userSlice'
import { useNavigate } from 'react-router-dom'


const ProfileCourses = () => {
    
    const {
        errorMessage,
        isError,
        isLoading,
        myCourses
        } = useSelector((store) => store.user)
    const navigate = useNavigate()

    

    
  return (
    <Wrapper>
        {
  !myCourses?.length && isLoading && !isError ? (
    <div className="loader-container">
      <Loader />
    </div>
  ) : !isLoading && isError ? (
    <div className="error-container">
      {errorMessage}
    </div>
  ) : myCourses?.length > 0 ? (
    <div className="course-list-container">
      {myCourses.map((values) => {
        const { _id, course_title, course_imageUrl, course_videoUrl } = values;
        return (
          <div key={_id} className="course-list">
            <img src={course_imageUrl} alt={course_title} />
            <div className="course-access">
              <h3>{course_title}</h3>
              <a href={course_videoUrl}
              target="_blank" rel="noopener noreferrer"
              >
                <button type="button">Access course</button>
              </a>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="empty-container">
      <p>You have no course</p>
      <button type="button" className="empty-btn" onClick={() => navigate('/course')}>Click to buy course</button>
    </div>
  )
}

    </Wrapper>
  )
}


const Wrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .course-list-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .course-list {
        margin-top: 2rem;
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0.5rem;
    }

    img {
        background: #f5f5f5;
        height: 70px;
        width: 25%;
        margin-right: 1.5rem;
        object-fit: cover;
    }

    .course-access {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        h3 {
            font-size: 0.9em;
            text-align: justify;
        }
    }

    a {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        /* height: 40px; */
    }

    button {
        height: 40px;
        width: 100%;
        border: solid 1px var(--stroke-color);
        background: none;
        color: var(--stroke-color);
        font-size: 0.9em;
        margin-top: 1rem;
    }

    .loader-container {
        margin-top: 3rem;
    }

    .error-container {
        margin-top: 1rem;
        color: var(--error-color);
    }

    .empty-container {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .empty-btn {
            margin-top: 1rem;
            padding: 1rem;
            display: grid;
            place-content: center;
        }
    }

`
export default ProfileCourses