import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom';
import ProfileSection from '../components/profile/ProfileSection'
import {updateActiveComponent} from '../slices/profileSlice'
import {initialCaps} from '../utils/utilsFunctions'

const data = [{
  id: 0,
  name: 'courses'
}, {
  id: 1,
  name: 'logout'
},]

const ProfilePage = () => {
  const {
    activeComponent
  } = useSelector((store) => store.profile)
  const location = useLocation()
    const currentPath = location.pathname.toString().substring(1)
  const dispatch = useDispatch()

  return (
    <Wrapper>
        <h1>Profile</h1>
        <div className="route-container">
            <div>
                <Link to={'/'}>home </Link>
                / {currentPath} 
            </div>
        </div>
        <div className="navigate-container">
            {data.map((values) => {
              const {id, name} = values
              return <h3 
              key={id}
              className={`${activeComponent === id && 'active'}`}
              onClick={() => dispatch(updateActiveComponent(id))}
              >{initialCaps(name)}</h3>
            })}
        </div>
        <div className="render-component">
        <ProfileSection/>
        </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100vw;
    padding-bottom: 45em;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-top: 8rem;
        color: var(--header-text-color);
    }

    .route-container {
        margin-top: 3rem;
        width: 90%;
        opacity: 0.8;
    }

    .navigate-container {
      margin-top: 2rem;
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      user-select: none;

      h3 {
        position: relative;
        font-size: 1em;
      }

      .active {
        color: var(--highlight-color);
        text-decoration: underline;
      }


    }

    .render-component {
      width: 90%;
      margin-top: 1rem;
    }

@media only screen and (min-width: 600px) {
    .route-container {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .navigate-container {
      width: 50%;
    }

    .render-component {
      width: 70%;
    }
  }


@media only screen and (min-width: 768px) {

  .navigate-container {
      width: 30%;
    }

    .render-component {
      width: 80%;
    }

}

@media only screen and (min-width: 992px) {
  padding-bottom: 50em;

  .navigate-container {
      width: 10%;
    }

    .render-component {
      width: 35%;
    }
  
}
`

export default ProfilePage