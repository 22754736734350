export const ratesData = [
    {
      id: 0,
      code: 'NGN',
      icon: '₦',
      name: 'Naira',
      selected: true
    },
    {
      id: 1,
      code: 'USD',
      icon: '$',
      name: 'Dollar',
    },
  ]