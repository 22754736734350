import React from 'react'
import styled from 'styled-components'
import {AdminSection} from '../components/admin'

const AdminDashboardPage = () => {
  return (
    <Wrapper>
        <AdminSection/>
    </Wrapper>
  )
}


const Wrapper = styled.div`
    width: 100vw;
    padding-bottom: 40em;

    @media only screen and (min-width: 992px) {
    padding-bottom: 45em;
  }
`

export default AdminDashboardPage