import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RxCross2 } from "react-icons/rx";
import { IoAddCircleSharp } from "react-icons/io5";
import AlertError from '../../helpers/AlertError'
import {toggleCourseModal, editCourse, fillCourseInputs, toggleEditCourseModal} from '../../slices/courseSlice'
import Skeleton from 'react-loading-skeleton';
import {getImageFile, uploadFile, removeImage, fillCourseEditImage} from '../../slices/uploadSlice'

// editCourseModalOpen

const EditCourseSection = () => {
    
  const { 
    courseModalOpen, 
    editCourseError, 
    editCourseErrorMsg, 
    createCourseLoad, 
    fillCourseInputs: {
    course_title, 
    course_description, 
    course_videoUrl, 
    course_price,
  }, 
  editCourseLoad,
  editCourseModalOpen,
  courseEditId,
  courseEditImageUrl,
  isEditing,
  

} = useSelector((store) => store.course);
const { files, images, isLoading} = useSelector((store) => store.upload);
  const dispatch = useDispatch();

  
  const handleUploadFile = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*'
    fileInput.multiple = true
    document.body.appendChild(fileInput);

    fileInput.addEventListener('change', (e) => {
        if (fileInput.files.length > 0) {
            const filesArray = Array.from(fileInput.files);
            dispatch(getImageFile(filesArray))
        }

        document.body.removeChild(fileInput);
    })

    fileInput.click()
      
  }

  useEffect(() => {
    if (files) {
        dispatch(uploadFile(files))
    }
  }, [files, dispatch]);

  useEffect(() => {
    if (isEditing) {
        dispatch(fillCourseEditImage({name: course_title, url: courseEditImageUrl}))
    }
      
  }, [isEditing, course_title, courseEditImageUrl, dispatch]);

  const {name, url} = images?.[0] || {}
  
 

  return (
    <Wrapper editCourseModalOpen={editCourseModalOpen}>
      <div className="create-course-container">
            <div className="modal-close-container" onClick={() => dispatch(toggleEditCourseModal())}>
                <RxCross2/>
            </div>
            <h1>Edit course</h1>
            <form className='course-form'>

                {/* course title  */}
                <label htmlFor="Title">
                    <h3>Title</h3>
                    <input type="text" 
                    required
                    value={course_title}
                    onChange={(e) => dispatch(fillCourseInputs({
                        course_title: e.target.value,
                        course_description,
                        course_videoUrl,
                        course_price
                    }))}
                    />
                </label>

                {/* course description  */}
                <label htmlFor="Description">
                    <h3>Description</h3>
                    <textarea
                    required
                    className='description-input'
                    value={course_description}
                    onChange={(e) => dispatch(fillCourseInputs({
                        course_description: e.target.value,
                        course_title,
                        course_videoUrl,
                        course_price
                    }))}
                    ></textarea>
                </label>

                {/* course price  */}
                <label htmlFor="Title" className='price-label'>
                    <h3>Price (₦)</h3>
                    <div className="price-label-inputs">
                    <input type="number" 
                    required
                    value={course_price}
                    onChange={(e) => dispatch(fillCourseInputs({
                        course_price: e.target.value,
                        course_title,
                        course_description,
                        course_videoUrl,
                    }))}
                    />
                    </div>
                </label>

                {/* course video url  */}
                <label htmlFor="Video">
                    <h3>Video url</h3>
                    <input type="url" 
                    required
                    placeholder='https://'
                    value={course_videoUrl}
                    onChange={(e) => dispatch(fillCourseInputs({
                        course_videoUrl: e.target.value,
                        course_title,
                        course_description,
                        course_price
                    }))}
                    />
                </label>

                {/* course image  */}
                <label htmlFor="Image">
                    <h3>Upload Image</h3>
                    <div className="image-container">
                        {!images?.length && isLoading ?  <div className='skeleton-img'>
                            <Skeleton height={150}/>
                        </div> : <>
                        <div className={`remove-image-container ${!images.length ? 'hide-img-rm' : ''}`} onClick={() => dispatch(removeImage())}>
                            <RxCross2 />
                        </div>
                        <img src={url} alt={name} className={`${!images.length && 'hide-img'}`}/>
                        </>}
                    </div>
                    <button type="button" className='upload-image-button' 
                    onClick={handleUploadFile}
                    >
                        <IoAddCircleSharp className='add-icon'/>
                        upload
                    </button>
                </label>

                {editCourseError && 
                <AlertError message={editCourseErrorMsg}/>}

                {/* submit button  */}
                <button type="button" className={`submit-btn ${editCourseLoad ? 'btn-load ' : ''}`} 
                onClick={() => dispatch(editCourse({
                    course_title, 
                    course_description, 
                    course_imageUrl: url, 
                    course_videoUrl, 
                    course_price,
                    id: courseEditId
                }))}
                >
                    Save
                </button>
            </form>
        </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
   position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    transform: ${({ editCourseModalOpen }) => (editCourseModalOpen ? 'translateX(0)' : 'translateX(-100%)')}; 
    /* opacity: ${({ courseModalOpen }) => (courseModalOpen ? 1 : 0)};  */
    transition: transform 0.1s ease-in-out;
    overflow: none;

    .modal-close-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 2em;
        cursor: pointer;
    }

    .create-course-container {
        background: var(--secondary-color);
        border: solid 2px black;
        width: 90%;
        height: 90%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2rem;
        overflow-y: auto;
    }

    .create-course-container::-webkit-scrollbar {
        width: 6px; 
    }

    .create-course-container::-webkit-scrollbar-track {
        background: #d3d3d3; 
    }

    .create-course-container::-webkit-scrollbar-thumb {
        background: var(--stroke-color);
     }


    form {
        width: 100%;
    }

    label {
        width: 100%;

        h3 {
            margin-top: 1rem;
            color: var(--text-color);

        }

        input {
            width: 100%;
            height: 50px;
            padding: 0 2rem 0 1rem;
            margin-top: 0.5rem;
            border-radius: 5px;
            border: solid 2px var(--stroke-color);
            font-size: 1em;
        }
    }

    .description-input {
        width: 100%;
        min-height: 50px;
        min-width: 100%;
        max-width: 100%;
        padding: 1rem 2rem 1rem 1rem;
        margin-top: 0.5rem;
        border-radius: 5px;
        border: solid 2px var(--stroke-color);
        font-size: 1em;
    }

    .price-label {
        width: 100%;
        
    }

    .price-label-inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        select {
            height: 50px;
            width: auto;
            padding: 0.5rem;
            display: grid;
            place-content: center;
            margin: 0.5rem 0.5rem 0 0;
            border: solid 2px var(--stroke-color);
            border-radius: 5px;
        }
    }

    .image-container {
        width: 100%;
        height: auto;
        background: none;
        position: relative;

        .remove-image-container {
            position: absolute;
            top: 15%;
            right: 5%;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background: var(--stroke-color);
            color: var(--white-color);
            display: grid;
            place-content: center;
            cursor: pointer;  
        }


        .hide-img-rm {
            display: none;
        }

        img {
            width: 100%;
            min-height: 150px;
            max-height: 200px;
            margin-top: 1rem;
            object-fit: cover;
            background: #f5f5f5;
        }

        .hide-img {
            display: none;
        }

        .skeleton-img {
            margin-top: 1rem;
            width: 100%;
            height: 100%;
        }
    }


    .upload-image-button {
        width: 100%;
        height: 50px;
        margin-top: 0.5rem;
        font-size: 1.1em;
        cursor: pointer;
        background: none;
        border: solid 2px black;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .add-icon {
            margin-right: 0.25rem;
            font-size: 1.5em;
        }
    }

    .submit-btn {
        margin-top: 2rem;
        width: 100%;
        height: 50px;
        font-size: 1.1em;
        background: var(--highlight-color);
        border: none;
        cursor: pointer;
        color: var(--white-color);
    }

    .btn-load {
        opacity: 0.8;
    }


@media only screen and (min-width: 600px) {
    .create-course-container {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) {
    .create-course-container {
        width: 60%;
    }
}


@media only screen and (min-width: 992px) {
    .create-course-container {
        width: 50%;
    }
}

`
export default EditCourseSection