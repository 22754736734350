import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {standardNumber, standardRate} from '../../utils/utilsFunctions'
import styled from 'styled-components'
import { RxCross2 } from "react-icons/rx";
import {changeCartQuantity, deleteCartItem, updateCartItem} from '../../slices/cartSlice'


const SingleCartItem = ({type, _id, imageUrl, name, price, quantity }) => {
  
  const { selectedRate, rates, conversionRate} = useSelector((store) => store.event);
  const { cartPopupOpen,
    isCartLoading,
    isCartError,
    errorMessage,
    successMessage,
    cart,
    quantity: newQuantity
  } = useSelector((store) => store.cart);
  const dispatch = useDispatch()
    const getRateSchema = rates.find(val => val.code === selectedRate)
    const {icon} = getRateSchema
  return (
    <Wrapper>
        {/* image container  */}
          <div className="image-container">
          <img src={imageUrl} alt={name} />
          </div>

        {/* details container  */}
      <div className="details-container">
          <div className="desc-container">
              <h3>{name}</h3>
              <div className="other-desc">
                  <p>Type: {type}</p>
                  {type !== 'course' && <>
                    <p>Size: XL</p>
                    <p>Color: Black</p>
                  </>}
              </div>

            <div className="quantity-container">
              {type !== 'course' && <input type="numer" 
                placeholder='1'
                value={newQuantity}
                defaultValue={quantity}
                onChange={(e) => dispatch(updateCartItem({id: _id, quantity: e.target.value}))}
                />
                }
            </div>

          </div>

          {/* price container  */}
        <div className="price-container">
          <RxCross2 className="rm-cart" onClick={() => dispatch(deleteCartItem({id: _id}))}/>
          <p>{icon}{standardNumber(standardRate(price, conversionRate))}</p>
        </div>
      
      </div>

    {/* </div> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`

    width: 100%;
    min-height: 100px;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    h3 {
      font-size: 1.1em;
    }

    p {
      font-size: 0.9em;
    }

    .image-container {
      width: 150px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;
    }


    img {
        height: 70px;
        width: 100%;
        object-fit: cover;
        border: solid 1px black;
    }

  .details-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

  }


  .desc-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .quantity-container {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    cursor: pointer;
    user-select: none;

    input {
      width: 100%;
      padding: 0.5rem;
      border: solid 1px black;
    }

    .quantity--icon {
      background: #000000;
      color: #ffffff;
      font-size: 1.1em;
      padding: 0.2rem;
    }

  }

  .rm-cart {
    cursor: pointer;
    font-size: 1.3em;
  }

  .price-container {
    height: 100%;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    p {
      margin-top: 1rem;
    }
  }

  .other-desc {
    margin-top: 1rem;
  }


`
export default SingleCartItem