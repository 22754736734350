import React, {useEffect} from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import SingleCourseSection from './SingleCourseSection'
import { IoAddCircleSharp } from "react-icons/io5";
import { HiOutlineDotsVertical } from "react-icons/hi";
import {toggleCourseModal, getCourses, closeCoursePopOver} from '../../slices/courseSlice'
import CourseLoadSkeleton from './CourseLoadSkeleton'
import ContentLoader from '../../helpers/ContentLoader'


const CourseSection = () => {
    const { courseModalOpen, courses, isLoading} = useSelector((store) => store.course);
    const { isAdmin, myCourses} = useSelector((store) => store.user);
    const dispatch = useDispatch()
    const location = useLocation()
    const currentPath = location.pathname.toString().substring(1)

    // console.log(courses)

    
  return (
    <Wrapper>
        <h1 className='course-title-label'>Courses</h1>
        <div className="route-container">
        <div>
                <Link to={'/'}>home </Link>
                / {currentPath}
            </div>
        </div>
        
            <div className='course-list-container'>

                {isAdmin && 
                <div className="add-course-container" onClick={() => dispatch(toggleCourseModal())}>
                    <IoAddCircleSharp className='add-icon'/>
                    <p>Add course</p>
                </div>
                }
                <div className="course-list-wrapper">
                {isLoading && courses?.length === 0 ? Array(courses?.length || 1).fill().map((_, i) => {
                    return <CourseLoadSkeleton key={i}/>
                }) : courses?.length > 0 ? courses.map((details) => {
                    return <SingleCourseSection {...details} key={details._id}/>
                }) : <div className="empty-course-display">
                <p>No available course!</p>
                </div>
                }
                </div>

            </div>
        
    </Wrapper>
  )
}

const Wrapper = styled.div`
    margin-top: 8rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .course-title-label {
        color: var(--header-text-color);
    }


    .route-container {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 80%;
        opacity: 0.8;
    }

    .course-list-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;
    }

    .add-course-container {
        width: 100%;
        height: 100px;
        border: solid 0.5px var(--stroke-color);
        background: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .add-icon {
        font-size: 2.5em;
        }

        p {
            margin-top: 0.5rem;
        }
    }

    .course-list-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .empty-course-display {
        margin-top: 3rem;
    } 

    .loader-container {
        margin-top: 3rem;
        width: 100%;

        >* {
            margin-top: 1rem;
        }
    }



@media only screen and (min-width: 600px) {
    .route-container {
        width: 70%;
    }
    .course-list-container {
        width: 70%;
    }
}

@media only screen and (min-width: 768px) {
    
        .route-container {
            justify-content: center;
        }

        .course-list-container {
            align-items: center;
            width: 80%;
        }

        .add-course-container {
            width: 50%;
        }

        .course-list-wrapper {
            width: 100%;
            display: grid;
            place-items: initial;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-template-rows: subgrid;
            gap: 1rem;
            margin-top: 2rem;
        }

        padding-bottom: 5em;


}


`
export default CourseSection