import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CheckoutItemSkeleton = () => {
  return (
    <Wrapper>
        <div className="image-container">
            <Skeleton height={70}/>
        </div>
        <div className="order-desc-container">
            <div className="h-container">
                <Skeleton height={30}/>
            </div>
            <div className="p-container">
                <Skeleton height={30}/>
            </div>
         </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;

    .image-container {
    height: 70px;
    width: 100px;
    object-fit: cover;
    margin-right: 2rem;
    }

    .order-desc-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

export default CheckoutItemSkeleton