import React from 'react'
import SingleProductSection from './SingleProductSection'
import styled from 'styled-components'
import Merch_1 from '../../assets/images/merch_1.jpg'
import Merch_2 from '../../assets/images/merch_2.jpg'
import Merch_3 from '../../assets/images/merch_3.jpg'
import Merch_4 from '../../assets/images/merch_4.jpg'

const data = [{
    id: 0,
    img: Merch_1,
    name: 'B&W HeyBro Tees',
    price: 12300
}, 
{
    id: 1,
    img: Merch_2,
    name: 'Wet Bros Hoodie',
    price: 2000
}, 
{
    id: 2,
    img: Merch_3,
    name: 'B&W HeyBro Tees',
    price: 2000
}, 
{
    id: 3,
    img: Merch_4,
    name: 'Pink Shepe 2',
    price: 2000
}, 


]


const ProductSection = () => {
  return (
    <Wrapper>
        <h1>NFT Merch</h1>
        <div className="clothing-products-container">
            {data.map((val) => {
                return <SingleProductSection {...val} key={val.id}/>
            })}
        </div>
        <button type='button' className='not-avail-btn'>Not Available...</button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 5rem;

    h1 {
        color: var(--header-text-color);
    }


    .clothing-products-container {
        margin-top: 2.5rem;
        display: grid;
        grid-template-columns: auto auto;
        width: 80%;
        gap: 1rem;
    }

    button {
        width: 80%;
        height: 60px;
        margin-top: 2rem;
        display: grid;
        place-content: center;
        cursor: pointer;
        font-size: 1em;
        background: none;
        border: solid 2px black;
    }

    .not-avail-btn {
        color: var(--primary-text-color);
    }

@media only screen and (min-width: 768px) {
    .clothing-products-container {
        width: 80%;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-template-rows: subgrid;
    }

    button {
        width: 50%;
    }
}

@media only screen and (min-width: 992px) {

    h1 {
        margin-top: 2rem;
    }
    .clothing-products-container {
        width: 90%;
        place-items: initial;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: subgrid;
        row-gap: 0rem;
        grid-auto-rows: minmax(50px, auto); 
        padding-bottom: 2rem;
    }

    button {
        width: 20%;
        margin: -0.5rem;
    }
}

`

export default ProductSection