import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {URL} from '../paths/url'

const initialState = {
    searchInput: '',
    displaySearch: false,
    searchLoad: false,
    searchError: false,
    searchErrorMsg: '',
    searchResult: [],
}

export const searchProducts = createAsyncThunk(
    'actions/searchProducts',
    async (payload, thunkAPI) => {
      const { search } = payload
      try {
        const resp = await axios.get(
          `${URL}/api/v1/product/search?search=${search}`,
          {
            withCredentials: true,
          }
        )
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )


const authSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        fillSearchInput: (state, action) => {
            state.searchInput = action.payload
        },
        closeSearch: (state, action) => {
            state.searchInput = ''
            state.displaySearch = false
        },
        handleDisplaySearch: (state, action) => {
            state.displaySearch = true
        }
    },

    extraReducers(builder) {
        builder
        .addCase(searchProducts.pending, (state, action) => {
            state.searchLoad = true
            state.searchError = false
          })
          .addCase(searchProducts.fulfilled, (state, action) => {
            state.searchLoad = true
            const { status, response } = action.payload;

             if (status === 'success') {
              state.searchLoad = false
              state.searchError = false
              state.searchResult = response.products

            } else {
              state.searchLoad = false
              state.searchError = true
              state.searchErrorMsg = response.msg              
            }
          })
          .addCase(searchProducts.rejected, (state, action) => {
            state.searchLoad = false
            state.searchError = true
            state.errorMessage = 'Unable to search'
          })
    }
      
    })


export default authSlice.reducer
export const {
  fillSearchInput,
  closeSearch,
  handleDisplaySearch
} = authSlice.actions