import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {URL} from '../paths/url'

const initialState = {
    courseImageUrl: '',
    isLoading: false,
    isError: false,
    errorMessage: '',
    files: null,
    images: []
}

export const uploadFile = createAsyncThunk(
    'actions/upload',
    async (payload, thunkAPI) => {        
        const formData = new FormData();
        payload.forEach((file) => {
        formData.append('image', file); 
        });

            try {
                const resp = await axios.post(
                  `${URL}/api/v1/file/upload`,
                    formData, 
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        withCredentials: true
                    },
                )
                
                return { response: resp.data, status: 'success' }
              } catch (error) {
                console.log('error', error)
                return {
                  response: error.response.data,
                  status: 'error',
                  code: error.response.status,
                }
              }
        }
  )



const authSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        getImageFile: (state, action) => {
            state.files = action.payload
        },
        removeImage: (state, action) => {
            state.files = null
            state.images = []
        },
        fillCourseEditImage: (state, action) => {
            const {name, url} = action.payload
            state.images = [{name, url}]
            
        }
    },
    extraReducers(builder){
        builder.addCase(uploadFile.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
        })
        .addCase(uploadFile.fulfilled, (state, action) => {
            state.isLoading = true
            const {response, status} = action.payload

            if (status === 'success') {
                state.isLoading = false
                state.isError = false
                state.images = response.data
                state.files = null
            } else {
                state.isLoading = false
                state.isError = true
                state.errorMessage = response.msg
            }

        })
        .addCase(uploadFile.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = 'Something went wrong'
        })
    }
})

export default authSlice.reducer
export const {
    getImageFile,
    removeImage,
    fillCourseEditImage
} = authSlice.actions