import React from 'react'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components'
import LazyLoad from 'react-lazyload';
// import {standardNumber} from '../../utils/numberFormatter'
import {standardNumber, standardRate} from '../../utils/utilsFunctions'



const SingleProductSection = ({img, name}) => {
    const { selectedRate, rates } = useSelector((store) => store.event);
    const getRateSchema = rates.find(val => val.code === selectedRate)
    const {icon} = getRateSchema
  return (
    <Wrapper>
        <img src={img} alt={name} loading='lazy'/>
        <div className="product-text-container">
            <p>{name}</p>
            <p>{icon}*****</p>
        </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
        height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: 50% 0;
        margin-top: 1rem;
        background: #f5f5f5;
    }

    .product-text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    p {
        margin-top: 0.5rem;
        color: var(--text-color);
    }

    @media only screen and (min-width: 768px) {
        img {
            min-height: 200px;
        }
    }

    @media only screen and (min-width: 992px) {
        img {
            min-height: 300px;
            width: 100%;
            height: 70%;
        }
    }

`
export default SingleProductSection