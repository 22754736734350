import React from 'react'
import styled from 'styled-components'
import {CartSection} from '../components/cart'

const CartPage = () => {
  return (
    <Wrapper>
        <CartSection/>
    </Wrapper>
  )
}

const Wrapper = styled.div`

   width: 100vw;
   padding-bottom: 45em;

   @media only screen and (min-width: 992px) {
    padding-bottom: 50em;
}
`

export default CartPage