import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { RxCross2 } from "react-icons/rx";
import { toggleDeleteCourseModal, closeCoursePopOver} from '../../slices/courseSlice'
import {deleteCourse} from '../../slices/courseSlice'




const DeleteCourseSection = () => {
    // courseDeleteId: "",
    // deleteCourseLoad: false,
    // deleteCourseError: false,
    // deleteCourseErrorMsg: ""
    const { deleteCourseModalOpen, delCourseName, courseDeleteId, deleteCourseLoad} = useSelector((store) => store.course);
    const dispatch = useDispatch()
  return (
    <Wrapper deleteCourseModalOpen={deleteCourseModalOpen}>
        <div className="delete-modal-container">
        <div className="modal-close-container">
                <RxCross2 onClick={() => {
                    dispatch(toggleDeleteCourseModal(""))
                }}/>
        </div>
        <h1>Delete Course</h1>
        <p>Are you sure you want to delete <strong> {delCourseName} </strong> course?</p>
        <div className="btn-container">
            <button type="button" className={`delete-btn ${deleteCourseLoad && 'btn-loading'}`} onClick={() => dispatch(deleteCourse({id: courseDeleteId}))}>Delete</button>
            <button type="button" onClick={() => dispatch(toggleDeleteCourseModal(""))}>Exit</button>
        </div>
        </div>
    </Wrapper>
  )
}


const Wrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000 !important;
    background: rgba(0, 0, 0, 0.9); 
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${({ deleteCourseModalOpen }) => (deleteCourseModalOpen ? 'translateY(0)' : 'translateY(-100%)')};
    opacity: ${({ deleteCourseModalOpen }) => (deleteCourseModalOpen ? 1 : 0)};
    transition: transform 0.1s ease-in-out;
    overflow: none;

    .delete-modal-container {
        background: var(--secondary-color);
        border: solid 2px black;
        width: 90%;
        height: auto;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2rem;
        overflow-y: auto;
    }

    .modal-close-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 1.5em;
        cursor: pointer;
    }

    p {
        margin-top: 1.5rem;
    }

    .btn-container {
        margin-top: 1.5rem;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        gap: 1rem;
    }

    button {
        width: 100%;
        height: 45px;
        border: none;
        cursor: pointer;
        border: none;
        background: #000000;
        color: var(--white-color);
        font-size: 1em;
    }

    .delete-btn {
        background: var(--error-color);
    }

    .btn-loading {
        opacity: 0.8;
    }

    @media only screen and (min-width: 768px) {
        .delete-modal-container {
        width: 60%;
        }
    }

    @media only screen and (min-width: 992px) {
        .delete-modal-container {
        width: 40%;
        }
    }


`
export default DeleteCourseSection